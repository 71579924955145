<!-- Navbar -->
<app-navbar [loading]="loading" backroute="..">
  <a class="navbar-brand">
      Fatture e Sal
  </a>
  
</app-navbar>

  
  <!-- Filters Section -->
  <div class="bg-light px-3">
    <div class="container-fluid py-3">

      <div class="row g-3 align-items-center ">
        <!-- Settore Buttons -->
        <div class="col-md-8">
          <div class="d-flex align-items-center flex-wrap">
            <span class="me-2">Settore:</span>
            <button 
                    [ngClass]="!selectedArea ? 'btn-primary' : 'btn-dark'"
                    class="btn ms-2">
              <i class="fa fa-asterisk"></i>
            </button>
            <button *ngFor="let area of areas" 
                    (click)="selectedArea = area.name; loadInvoices()"
                    [ngClass]="selectedArea == area.name ? getAreaBadgeClass(area.name) : 'btn-dark'" 
                    class="btn ms-2">
              {{ area.name }}
            </button>
          </div>
        </div>

        <!-- Reset Filters -->
        <div class="col-md-12 col-lg-3 d-flex justify-content-start justify-content-lg-end align-items-center">
          <button class="btn bg-info btn-sm ms-2 px-3 text-light" (click)="resetFilters()">Reset Filtri</button>
        </div>
      </div>
  

    <!-- second -->



      <div class="row g-3 py-3">
        <!-- Search by Customer -->
        <div class="col-md-3 col-lg-3">           
          <div class="input-group">
            <span class="input-group-text">Cerca</span>
            <input class="form-control" placeholder="Cliente, commessa o numero di ordine" [ngModel]="searchInput | async" (ngModelChange)="searchInput.next($event)">
          </div>
        </div>
  
        <!-- Search by User -->
        <div class="col-md-3 col-lg-2">
          <select class="form-select" [(ngModel)]="selectedUserId" (ngModelChange)="onUserChange($event)">
            <option [ngValue]="null">Tutti gli utenti</option>
            <option *ngFor="let user of users" [ngValue]="user.id">{{user.name}}</option>
          </select>
        </div>
        <!-- Date Range Filter -->
        <div class="col-md-3 col-lg-4">
          <div class="input-group">
            <span class="input-group-text">Data Fattura</span>
            <input type="date" class="form-control" [(ngModel)]="startDate" (change)="loadInvoices()">
            <span class="input-group-text">a</span>
            <input type="date" class="form-control" [(ngModel)]="endDate" (change)="loadInvoices()">
          </div>
        </div>
  
      </div>
    </div>
  </div>
  
  <!-- Invoice Table -->
  <div class="table-responsive mt-3">
    <table class="table table-hover align-middle mb-0">
      <thead>
        <tr>
          <th>Responsabile</th>
          <th>Commessa</th>
          <th>Fattura</th>
          <th>Data Fattura</th>
          <th>Imponibile</th>
          <th colspan="2" class="text-center">IVA</th>
          <th>Totale</th>
        </tr>
      </thead>
         @if (!loading) {
          <tbody>
            
            <tr (click)="redirectDeal(getLead(invoice.invoice_origin).id)" target="_blank" *ngFor="let invoice of filteredInvoices" class="align-middle">
            
            
              <!-- User Column -->
              <td>{{ invoice.invoice_user_id.name }}
                
              </td>
            
              <!-- Deal/Customer Information -->
              <td>
                <span class="fw-bold">{{ invoice.invoice_origin }} - {{ getLead(invoice.invoice_origin)?.partner_id?.name }} - {{ getLead(invoice.invoice_origin)?.name }}</span>
                <br>
                <span>{{ getLead(invoice.invoice_origin)?.street }} - {{ getLead(invoice.invoice_origin)?.city }}</span>
                <span 
                  class="ms-3 badge" 
                  [ngClass]="getAreaBadgeClass(getLead(invoice.invoice_origin)?.area)"
                  style="white-space: nowrap;">
                  {{ getLead(invoice.invoice_origin)?.area }}
                </span>
              </td>
            
              <!-- Narration Column -->
              <td [innerHTML]="invoice.narration"></td>
            
              <!-- Invoice Date Column -->
              <td>{{ invoice.invoice_date | Date4Humans }}</td>
            
              <!-- Untaxed Amount Column -->
              <td>{{ invoice.amount_untaxed | currency: 'EUR' }}</td>
            
              <!-- Tax Amount Column -->
              <td>{{ invoice.amount_tax | currency: 'EUR' }}</td>
            
              <!-- Tax Percentage Column -->
              <td>{{ (invoice.amount_tax / invoice.amount_untaxed * 100) | number: '1.0-2' }}%</td>
            
              <!-- Total Amount Column -->
              <td>{{ invoice.amount_total | currency: 'EUR' }}</td>
            </tr>
      </tbody>
    }
    </table>
  </div>