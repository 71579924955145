
<app-navbar [loading]="loading" class="sticky-top" [backroute]="backroute" >
  <a class="navbar-brand text-wrap fs-6">
      {{picking?.origin}} - {{picking?.name.replace("LOM/","")}}
      <br>
      {{picking?.picking_type_id.name.replace("LOMAGNA: ","")}}
    </a>
    <button class="ms-auto me-2 btn btn-primary text-white" *ngIf="picking?.state == 'assigned' || picking?.state == 'confirmed' || picking?.state == 'waiting'" (click)="confirm()">Conferma</button>
    <!-- button to open page in 03 -->
    <a class="ms-2 me-2 text-white fa-solid fa-arrow-up-right-from-square" 
            target="_blank" 
            href="https://o3.galimberti.eu/web#id={{picking?.id}}&cids=1&menu_id=223&action=375&model=stock.picking&view_type=form">
    </a>
    <button class="ms-2 me-2 btn btn-success text-white disabled" *ngIf="picking?.state == 'done'">Confermato</button>
</app-navbar>


<div class="bg-light border-bottom p-2 d-flex align-items-center">
    <input type="checkbox" class="form-check-input m-0 mx-2 fs-3" 
      [checked]="getSelectedLines().length > 0" (change)="setSelectedAllLines($event.target.checked)">
    <span>Cerca&nbsp;&nbsp;</span><input class="form-control" type="search">
</div>

<!-- PICKING TABLE -->
<div *ngIf="picking" class="pb-5" style="height:100%; ">

  <div class="table-responsive" *ngIf="picking.package_level_ids.ids">
    <table class="mt-2 ms-2">
      <thead>
        <tr >
          <td colspan="2"><b>Note generali di spedizione:</b></td>
          
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colspan="2" class="align-middle text-danger">
            <!-- note generali del sale order di origine dello stock picking, se presenti-->      
            <div *ngIf="picking.sale_id.value.note" [innerHTML]="picking.sale_id.value.note"></div>
          </td>
        </tr>
      </tbody>
    </table>
    <table class="table table-bordered mb-5 bg-white">
      <thead>
        <tr class="bg-light">
          <td colspan="2"><b>Pacchi interi</b></td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let p of picking.package_level_ids.values">
          <td class="align-middle  py-2">
            <div class="form-check form-switch" style="font-size:1.5rem" >
              <input 
                [checked]="p.is_done"
                (change)="togglePack(p)"
                class="form-check-input" 
                type="checkbox"
                role="switch"
              >
            </div>
          </td>
          <td class="w-100 align-middle">{{p.package_id.name}}</td>
          
        </tr>
      </tbody>
    </table> 
  </div>

  <ng-container *ngFor="let p of packagesGroup | keyvalue">
    <div class="mt-3 " *ngIf="p.key != 'undefined'">
      <div class="table-responsive">
        <table class="table table-bordered mb-5 bg-white">
            <ng-container *ngFor="let line of groupItemBy(p.value, 'product_id.value.product_tmpl_id.name') | keyvalue">
              <thead>
              <tr class="bg-light">
                
                <th colspan="100" class="">
                  <div class="d-flex align-items-center justify-content-between">
                    <span>{{ line.key }}</span>
                    <button
                      class="btn text-white"
                      *ngIf="hasCreatedProductionId(line.value)"
                      title="Conferma produzione"
                      [disabled]="isProductionCompleted(line.value)"
                      [ngClass]="{
                        'bg-primary': !isProductionCompleted(line.value),
                        'bg-success': isProductionCompleted(line.value)
                      }"
                      (click)="completeProduction(line.value)"
                    >
                      <i class="fa-solid fa-hammer-crash"></i>
                    </button>
                  </div>
                </th>
            </tr>
          </thead>
              <tbody class="">
                <ng-container *ngFor="let l of line.value | sortBy2" >

                  <tr *ngIf="l.move_line_ids.values.length == 0">
                    <td  class="align-middle text-nowrap"  > 
                      <p class="mb-0 ">{{getDescriptiveTodo(l, ll)}}</p>
                    </td>

                    <td colspan="4" class="py-3 text-danger" (click)="newFrom(l)"><b>Nessuna prenotazione</b> </td>

                    <td class="text-nowrap my-3 align-middle" *ngFor="let va of l.product_id.value.product_template_variant_value_ids.values">
                      {{va.name}} 
                    </td>
                  </tr> 


                <tr *ngFor="let ll of l.move_line_ids.values">
                  
                  <td *ngIf="isMultiLine(l, ll) > 0" class="align-middle" [attr.rowspan]="isMultiLine(l,ll)" > 
                    <div class="d-flex align-items-center ">
                     
                      <pre class="mb-0 ">{{getDescriptiveTodo(l, ll)}} </pre>
                    </div>
                    
                  </td>

                  <td style="width: 0px;" class="align-middle">
                    <input type="checkbox" class="form-check-input m-0 mx-1 fs-3" 
                    (change)="ll._checked = $event.target.checked"
                    [checked]="ll._checked" >
                  </td>
                  <td  style="min-width: 40px;"
                    class="align-middle text-nowrap"
                    (click)="onLine(ll)"
                  >
                    <b>{{getDescriptiveDone(ll)}}</b>
                  </td>

                  <!-- <td 
                      [ngClass]="{'bg-success text-white': l.qty_done == l.reserved_uom_qty, 'bg-danger text-white': l.qty_done == 0, 'bg-warning text-dark': l.qty_done < l.reserved_uom_qty && l.qty_done > 0}"
                      class="text-nowrap">
                      {{getDescriptiveDone(l)}}
                    </td> -->


                  <td class="align-middle">
                    <div class="dropdown position-static" data-bs-boundary="html" >
                      <button 
                      [disabled]="getSelectedLines().length > 0  || getProductType(l) === 'consu'"
                      class="btn mt-2 d-flex align-items-center justify-content-center text-nowrap"
                      [ngClass]="getPackOutline(l, ll)"
                      data-bs-toggle="dropdown"
                      data-bs-boundary="html"
                      aria-expanded="false">
                      <h2 class="mb-0">
                        <ng-container *ngIf="ll.package_id?.id; else noPackage">
                          {{ ll.package_id.name }}
                        </ng-container>
                        <ng-template #noPackage>
                          <ng-container *ngIf="getProductType(l) === 'consu'; else noCollo">
                            <i class="text-muted text-decoration-line-through"> 0000</i>
                          </ng-container>
                          <ng-template #noCollo>----
                          </ng-template>
                        </ng-template>
                      </h2>
                    </button>
                      <ul class="dropdown-menu  bg-dark" data-bs-strategy='fixed' data-bs-theme="dark" data-bs-boundary="">
                        <li class="dropdown-item" (click)="changeFrom(ll)" ><a class="" >Cambia pacco</a></li>
                        <li class="dropdown-divider"></li>
                        <li class=" dropdown-item" (click)="addFrom(ll)"><a class="" >Aggiungi pacco</a></li>
                        <!-- <li class="dropdown-divider"><a class=""></a></li>
                        <li class="dropdown-item"><a class="" (click)="addFrom(ll)">Mostra contenuto</a></li> -->
                      </ul>
                    </div>
                    <p class="mb-0 text-muted">
                      {{ll.location_id.name.replace("LOM/", "")}}
                    </p>
                    <p class="mb-0 text-danger text-wrap">
                      <b>{{ll._PackErrorText}}</b>
                    </p>
                  </td>

                  <td class="align-middle">
                    <div class="dropdown position-static">
                      <button class="text-nowrap btn d-flex mt-2 align-items-center justify-content-center"
                        [disabled]="getSelectedLines().length > 0 && !ll._checked || getProductType(l) === 'consu'"
                        [ngClass]="getPackOutline(l, ll)"                        
                         role="button" data-bs-toggle="dropdown" aria-expanded="false">
                         <h2 class="mb-0">
                          <ng-container *ngIf="ll.result_package_id?.id; else noPackage">
                            {{ ll.result_package_id.name }}
                          </ng-container>
                          <ng-template #noPackage>
                            <ng-container *ngIf="getProductType(l) === 'consu'; else noCollo">
                              <i class="text-muted text-decoration-line-through"> 0000</i>
                            </ng-container>
                            <ng-template #noCollo>----
                            </ng-template>
                          </ng-template>
                        </h2>
                      </button>             
                      <ul class="dropdown-menu bg-dark" data-bs-theme="dark">
                        <li class="dropdown-item" (click)="changeTo(ll)"> <a class="" >Cambia pacco</a></li>
                        <li class="dropdown-divider"></li>
                        <li class="dropdown-item" (click)="addTo(ll)"><a class="">Aggiungi pacco</a></li>
                        <li class="dropdown-divider"></li>
                        <li class="dropdown-item" (click)="removeTo(ll)"><a class="">Non usare questo pacco</a></li>
                      </ul>
                    </div>
                    <p class="mb-0 text-muted">
                      {{ll.location_dest_id.name.replace("LOM/", "")}}
                    </p>
                  </td>

                  <td class="text-nowrap my-3 align-middle" *ngFor="let va of l.product_id.value.product_template_variant_value_ids.values">
                      {{va.name}} 
                  </td>
                </tr>
              </ng-container>
              </tbody>
            </ng-container>
        </table>
    </div>
    </div>
  </ng-container>
  <!-- <app-package-chooser [picking]="picking"></app-package-chooser> -->
</div>


<!-- <app-scanner *ngIf="scanningBarcode" format="EAN-13" (onCode)="onScanBarcode($event)"></app-scanner> -->

<app-scanner *ngIf="scanningTo?.length" format="EAN-13" (onCode)="onTo($event)"></app-scanner>

<app-scanner *ngIf="scanningFrom?.length" format="EAN-13" (onCode)="onFrom($event)"></app-scanner>

<app-scanner *ngIf="addingFrom" format="EAN-13" (onCode)="onAddFrom($event)"></app-scanner>

<app-scanner *ngIf="addingTo" format="EAN-13" (onCode)="onAddTo($event)"></app-scanner>

<!-- MASTER LINE -->

<div *ngIf="masterLine" class="w-100 h-100 bg-white d-flex justify-content-center flex-column overflow-hidden"
  style="position: fixed; top: 0px;z-index:10001">
<bar-loader [loading]="loading"></bar-loader>

<div class="row">
  <div class="col bg-dark text-white">
    <div class=" mb-0  text-center">
      

      <table class="table mb-0  table-dark text-light table-borderless"> 
        <thead>
          <tr>
            <td colspan="2" class="py-3">
              <b>{{getDescriptive(masterLine.move_id.value,masterLine.move_id.value.product_uom_qty)}} 
                
                </b>di <br> {{masterLine.product_id.value.display_name}}
              
            </td>
          </tr>
        </thead>
        <tbody>
          <!-- <tr >
            <td class="pb-2">
              Richiesti
            </td>
            <td class="py-2">{{getDescriptive(masterLine.move_id.value,masterLine.move_id.value.product_uom_qty)}}</td>
          </tr> -->
          <!-- <tr>
            <td>{{masterLine.package_id.id ?  masterLine.package_id.name : 'Nessun imballo'}}</td>
          </tr> -->
          <tr>
            <td colspan="2" class="bg-dark">
              <b>{{masterLine.package_id.id ?  'Collo ' + masterLine.package_id.name : 'Nessun collo'}}</b>
            </td>
          </tr>
          
          <tr *ngIf="masterLine.package_id.id">
            <td class="py-2 pb-2 bg-dark">Totali</td>
            <td class="py-2 pb-2 bg-dark"><b>{{getTotalQtyInPackage(masterLine)}}</b></td>
          </tr>

          <tr *ngIf="masterLine.package_id.id">
            <!-- <td rowspan="3"></td> -->
            <td class="py-2 pb-3 bg-dark text-success">Utlizzabili</td>
            <td class="py-2 pb-3 bg-dark text-success"><b>{{getAvailableQtyInPackage(masterLine)}}</b></td>
          </tr>

          <!-- <tr *ngIf="masterLine.package_id.id">
            <td class="py-2 pb-4">Pre in {{masterLine.package_id.id ?  masterLine.package_id.name : 'Nessun imballo'}}</td>
            <td class="py-2 pb-4"><b>{{getAvailableQtyInPackage(masterLine)}}</b></td>
          </tr>
          <tr *ngIf="masterLine.package_id.id">
            <td class="py-2 pb-4">Prenotati per altri {{masterLine.package_id.id ?  masterLine.package_id.name : 'Nessun imballo'}}</td>
            <td class="py-2 pb-4"><b>{{getReservedForOthersQtyInPackage(masterLine)}}</b></td>
          </tr>

          <tr *ngIf="masterLine.package_id.id">
            <td class="py-2 pb-4">Prenotati per te {{masterLine.package_id.id ?  masterLine.package_id.name : 'Nessun imballo'}}</td>
            <td class="py-2 pb-4"><b>{{getReservedQtyInPackage(masterLine)}}</b></td>
          </tr> -->
        </tbody>
      </table>
    </div>
  </div>
</div>

<div class="row flex-fill align-items-center">
<div class="col p-4">
<table class="table bg-ligth table-bordered">
 
  <tbody>
      <tr *ngFor="let d of descriptiveArray;let i = index;">
        <td class="align-items-center d-flex p-3">
          <input 
            type="number"
            lang="it-IT"
            class="form-control d-inline pe-2" 
            [ngModelOptions]="{'updateOn':'blur'}"
            (ngModelChange)="updateDescriptiveOr(masterLine, d, $event )"
            [ngModel]="d[0]"
          >
          <span class="ps-2">{{d[1].name}}</span>
        </td>
      </tr>
      <tr>

      <td class="align-items-center d-flex p-3 bg-light text-nowrap">
        <input
          type="number"
          lang="it-IT"
          class="form-control d-inline pe-2" 
          [ngModel]="masterLine.qty_done "
          [ngModelOptions]="{'updateOn':'blur'}"
          (ngModelChange)="updateMaster(masterLine, 'qty_done', $event)"
        >
        <span class="ps-2 text-nowrap">{{masterLine.product_id.value.uom_id.name}}</span>
      </td>
    </tr>
  </tbody>
</table>
</div>
</div>

<div class="d-flex flex-column my-3 animate__animated" [class.animate__fadeOutDown]="loading" >
<div class="align-items-center d-flex mt-2 justify-content-center">
  
  <button class="btn btn-link  text-success ms-2 me-2"
    (click)="back()">
    <i class="fa-duotone fa-circle-chevron-left fa-3x text-dark"></i>
  </button>

  <button class="btn btn-link text-ms-2 me-2"
    [disabled]="order?.state == 'sale'"
    (click)="assignMoveLine(masterLine)">
      <i class="fa-duotone fa-box-check fa-3x"></i>
  </button>

  <button class="btn btn-link text-success ms-2 me-2"
    [disabled]="order?.state == 'sale'"
    (click)="save()">
      <i class="fa-duotone fa-circle-check fa-4x"></i>
  </button>
  
  <button *ngIf="masterLine.id != -1" class="btn btn-link  text-success text-warning ms-2"
    [disabled]="order?.state == 'sale'"
    (click)="deleteLine(masterLine)">
      <i class="fa-duotone fa-circle-trash fa-3x " ></i>
  </button>

</div>
</div>


</div>

