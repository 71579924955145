import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, TrackByFunction, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Lead } from 'src/app/models/crm.lead.model';
import { ODOO_IDS, PRINTS_CFG } from 'src/app/models/deal';
import { ProductAttributeValue } from 'src/app/models/product.attribute.value';
import { Product, ProductWithOnlyVariants } from 'src/app/models/product.model';
import { ProductTemplateAttributeValue } from 'src/app/models/product.template.attribute.value.model';
import { ProductTemplate } from 'src/app/models/product.template.model';
import { SaleOrderLine } from 'src/app/models/sale-order-line.model';
import { SaleOrder } from 'src/app/models/sale-order.model';
import { StockLocationRoute } from 'src/app/models/stock-location-route.model';
import { StockMove } from 'src/app/models/stock-move';
import { StockPicking } from 'src/app/models/stock-picking';
import { OdooEntityManager } from 'src/app/shared/services/odoo-entity-manager.service';
import { ProductPricelist } from 'src/app/models/product.pricelist';
import { firstValueFrom, merge } from 'rxjs';
import { AccountPaymentTerm } from 'src/app/models/account-payment-term.model';
import { AccountIncoTerm } from 'src/app/models/account-inco-term.model';
import { SaleOrderCancel } from 'src/app/models/sale-order-cancel.model';
import { ProductPackaging } from 'src/app/models/product.packaging.model';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { CrmLeadPart } from 'src/app/models/crm.lead.part.model';
import { ProcurementGroup } from 'src/app/models/procurement.group.model';
import { StockQuant } from 'src/app/models/stock-quant';

import Decimal from 'decimal.js'
import Dec from 'decimal.js'
import { Contact } from 'src/app/models/contact.model';
import { GapiService } from 'src/app/shared/services/g-api.service';
import { PurchaseOrderLine } from 'src/app/models/purchase-order-line.model';
import { PurchaseOrder } from 'src/app/models/order';
import { PriceList } from 'src/app/models/price.list.model';
import { AccountMoveLine } from 'src/app/models/account-move-line.model';
import { MailActivity } from 'src/app/models/mail.message';

// @Pipe({
//   name: 'myfilter',
//   pure: false
// })


@Component({
  selector: 'app-sale-order-editor',
  templateUrl: './sale-order-editor.component.html'
})
export class SaleOrderEditorComponent implements OnInit, AfterViewInit {


  @ViewChild('orderInventory') orderInvetory;
  identify: TrackByFunction<any> = (index, item) => item.id;

  // Component properties
  id: any;
  loading: boolean = true;
  selectableRoutes: StockLocationRoute[] = [];
  inventoryClosed:boolean = true
  pickings: StockPicking[];
  moves: StockMove[];
  pricelists: PriceList[]
  paymentTerms: AccountPaymentTerm[] = [];
  incoTerms: AccountIncoTerm[] = [];
  draggingLine: SaleOrderLine;
  isPrinting: boolean = false;
  roundedTotal: number;
  filterColumn: {'name'} = { 'name' : null }
  editingDiscount=  false
  stateTranslations = {
    'draft': 'Preventivo',
    'sent': 'Preventivo inviato',
    'sale': 'Ordine',
    'done': 'Ordine',
    'cancel': 'Ordine'
  };
  draggingQtyColumn: SaleOrderLine;
  selectablePackagings: ProductPackaging[];
  dragTarget: any;
  relatedSales: SaleOrder[] = [];
  detailPicking: StockPicking;
  saleIds: any;
  opportunity_code: string;
  activeSale: SaleOrder;
  thisIs: this;
  isMultiple: boolean;
  orderLinesToPrint: any[];
  part: CrmLeadPart;
  procurementGroup: ProcurementGroup;
  replenishQty:number = null;
  replenishPackaging: ProductPackaging;
  
  // Constructor to inject services
  constructor(
    private elementRef: ElementRef,
    private route: ActivatedRoute,
    private router: Router,
    private odooEm: OdooEntityManager,
    private gapi: GapiService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }


  async ngOnInit() {

    // Asynchronous search for a StockLocationRoute with the name 'DIRETTO'
    // let route = await firstValueFrom(this.odooEm.search<StockLocationRoute>(new StockLocationRoute(),[['name','=', 'DIRETTO']])) 

    // Subscribe to route parameters to get the order_id
    this.route.params.subscribe(async params => {
      this.id = params['order_id']

      // Asynchronous search for SaleOrder with the obtained order_id
      let orders = await firstValueFrom(this.odooEm.search<SaleOrder>(new SaleOrder(), [['id', '=', this.id]])) 
      if (orders.length > 0) {
        let order = orders[0]


         // Initialize an array to load related sales and resolve the sale order
         let p = []
         p.push(this.loadRelatedSales(order))
         p.push(this.resolveSale(order))
      }

      // Asynchronous search for AccountIncoTerm and update incoTerms once data is received
      this.pricelists = await firstValueFrom(this.odooEm.search<ProductPricelist>(new ProductPricelist()))
      this.odooEm.search<AccountIncoTerm>(new AccountIncoTerm()).subscribe(x => {
        this.incoTerms = x
      })
    })

    // Hack to bind 'this' context
    this.thisIs = this
  }

  
  ngAfterViewInit(): void {
  }

  hasPricelist(s:SaleOrder) {
    // Checks if the given SaleOrder has a pricelist that is not equal to the predefined 'pricelist_commessa'
    return (s.pricelist_id.id != ODOO_IDS.pricelist_commessa)
  }

  // async initReplenish($event,line) {
  //   $event.stopPropagation();
  //   await this.updateSelectablePackaging(line);
  //   this.replenishPackaging = this.selectablePackagings[0];
  //   this.replenishQty = line.product_id.value.qty_available / this.replenishPackaging.qty;
  // }

  selectLine(ev:PointerEvent, line:SaleOrderLine, o:SaleOrder) {
    
    var newValue = (ev.target as any).value

    // Handle multi-selection with shift key
    if ( ev.shiftKey) {
      // find the last selected line
      var sl = o.order_line.values.find(l => {
        return l._checked
      })

      var bi = o.order_line.values.indexOf(sl)
      var ei = o.order_line.values.indexOf(line)
      
      // Update the _checked property based on the range between the last selected line and the current line
      for (var x = 0; x < o.order_line.values.length; x++) {
        if (ei == bi) {
          o.order_line.values[x]._checked = false
          o._checked = false
        } else if (x >= bi && x < ei)
          o.order_line.values[x]._checked = true
        else {
          o.order_line.values[x]._checked = false
          o._checked = false
        }
      }
      // Update the isMultiple flag
      this.isMultiple = bi != ei
    }
        
    return true
  }

  selectOrder(ev, o:SaleOrder) {
    // Selects or deselects all lines in the SaleOrder based on the provided event value.
    if (o.order_line && o.order_line.values?.length > 0) {
      o.order_line.values.forEach(x => x._checked = ev)
    }
  }
  
  updateOrderCheckbox(o) {
    //Updates the _checked property of the SaleOrder based on the selection state of its lines.
    if (o.order_line && o.order_line.values?.length > 0) {
      var c = o.order_line.values.filter(x => x._checked == true)
      o._checked = c.length == o.order_line.values.length
    }
  }

 
 

  async loadRelatedSales(order:SaleOrder) { //Loads related sales for the given SaleOrder.
 
    // Search for CrmLeadPart related to the sale order
    var parts = await firstValueFrom(this.odooEm.search<CrmLeadPart>(new CrmLeadPart(), [['sale_order_ids', 'in', [order.id]]]))
    
    if (parts && parts.length) {
      // If parts are found, search for SaleOrders related to the CrmLeadPart
      this.part = parts[0]
      var s = await firstValueFrom(this.odooEm.search<SaleOrder>(new SaleOrder(), [["id", "in", parts[0].sale_order_ids.ids]]))
    } else {
      // If no parts are found, search for the SaleOrder with the given order id
      var s = await firstValueFrom(this.odooEm.search<SaleOrder>(new SaleOrder(), [["id", "=", order.id]]))
    }

    // Reverse the order of sales for further processing
    s.reverse()

    // Replace the original order in the list with the updated order object
    let o = s.find(x => x.id == order.id)
    let i = s.indexOf(o)
    s.splice(i,1,order)

     // Update the relatedSales and saleIds property with the modified list of sales
    this.relatedSales = s
    this.saleIds = null
    this.saleIds = s.map(x => "saleList-" + x.id).concat(['inventoryList', 'toolList'])


    await firstValueFrom(this.odooEm.resolveArray(new MailActivity(), this.relatedSales, "activity_ids"))

    
    // Compute the delivery state for each related sale
    this.odooEm.call2('sale.order', 'ga_compute_order_state', [ null, this.relatedSales.map(r => r.id)]).then(x => {
      // ga_compute_order_state returns an ordered array of strings in the result key
      this.relatedSales.forEach((r, i) => {
        r._delivery_state = x.result[i]
      })  
    })

    //

    // Resolve activities associated with the SaleOrder
    // firstValueFrom(this.odooEm.resolve(res[0].activity_ids)).then(() => {
    //   s.activity_ids.ids = res[0].activity_ids.ids
    //   s.activity_ids.values = res[0].activity_ids.values
    // })

        
    // Resolve the opportunity associated with the first related sale
    firstValueFrom(this.odooEm.resolveSingle(new Lead(), this.relatedSales[0].opportunity_id)).then(() => {
      this.opportunity_code =  this.relatedSales[0].opportunity_id.value?.tracking_code
    })
  }

  // getOrderState(s:SaleOrder) {
  //   //determino lo stato dell'ordine con appoggio alle funzioni sopra
  //   let c = ""
  //   console.log("CHECKING STATE ORDER ", s.name)
  //   if (s.state == 'sale' && (s.delivery_status == 'partial' || s.delivery_status == 'pending') && this.pickedOut(s)==false && this.orderArrived(s)==false && this.orderPrepared(s)==false)
  //     c = "Confermato"
  //   if ((s.state == 'sale' && s.delivery_status == 'full') || (s.state == 'sale' && !s.delivery_status))
  //     c = "Spedito interamente"
  //   if (s.state == 'sale' && s.delivery_status == 'partial' && !this.pickedOut(s) && this.orderArrived(s) && !this.orderPrepared(s))
  //     c = "Confermato - acquisti arrivati"
  //   if (s.state == 'sale' && s.delivery_status == 'partial' && !this.pickedOut(s) && this.orderPrepared(s))
  //     c = "Preparato - non spedito"
  //   if (s.state == 'sale' && s.delivery_status == 'partial' && this.pickedOut(s))
  //     c = "Spedito parzialmente"
  //   if (s.state == 'draft')
  //     c = "Bozza"
  //   if (s.state == 'cancel')
  //     c = "Annullato"
  //   console.log("DELI STATE ",s.name, c)
  //   return c
  // }
  

  async toggleSale(s:SaleOrder) { //Toggles the open state of a SaleOrder.
    if (!s._open) {
       // Resolve and open the sale order if it's not already open
      await this.resolveSale(s)
      s._open = true
    } else {
      // Close the sale order and clear its order lines
      s.order_line.values = null
      s._open = false
    }
  }
  

  async resolveSale(s:SaleOrder):Promise<SaleOrder> {

    this.loading = true
    // Fetch the SaleOrder details from the server
    var res = await firstValueFrom(this.odooEm.search<SaleOrder>(new SaleOrder(), [['id', '=', s.id]]))

    if (res.length != 1)
      throw alert("Errore nell aggiornamento")

    // component variables to store the progress of the resolution
    s._open = true
    s._resolving = true
    s._resolved = false
    s.state = res[0].state

    // Resolve the order lines and their associated products
    await firstValueFrom(this.odooEm.resolve(res[0].order_line))
    await firstValueFrom(this.odooEm.resolveArrayOfSingle(new Product(), res[0].order_line.values, 'product_id'))
    
    // Update the SaleOrder properties with the resolved data
    s.order_line.ids = res[0].order_line.ids
    s.order_line.values = res[0].order_line.values
    s.pricelist_id = res[0].pricelist_id
    s.amount_untaxed = res[0].amount_untaxed
    s.procurement_group_id.id = res[0].procurement_group_id.id
    s._open = true
    s._resolving = false

    // Collect all products from the order lines
    var products = []
    s.order_line.values.forEach(s => {
      if (s.product_id && s.product_id.id && s.product_id.value)
        products.push(s.product_id.value)
    })

    // Resolve product packagings and template attribute values if products exist
    if (products.length > 0) {
      await this.odooEm.resolveArray(new ProductPackaging(),products,"packaging_ids").toPromise()
      await this.odooEm.resolveArrayOfSingle(new ProductPackaging(),s.order_line.values,"product_packaging_id").toPromise()
      this.odooEm.resolveArray(new ProductTemplateAttributeValue(), products, "product_template_attribute_value_ids").toPromise()
    }


    //load procurement groups, purchases and account moves
    this.checkProcuremntGroup(s).then(() => {
      this.checkPurchases(s)
    })

    // Compute the delivery state of the SaleOrder
    this.odooEm.call2('sale.order', 'ga_compute_order_state', [ null, [s.id]]).then(x => {
      s._delivery_state = x.result[0]
    })
    
    // // Resolve activities associated with the SaleOrder
    // firstValueFrom(this.odooEm.resolve(res[0].activity_ids)).then(() => {
    //   s.activity_ids.ids = res[0].activity_ids.ids
    //   s.activity_ids.values = res[0].activity_ids.values
    // })
    
    // Set the active sale and mark the SaleOrder as resolved
    this.activeSale = s
    s._resolved = true
    this.loading = false

    return s
  }

// CODE IS COMMENTET UP TO THIS POINT
  async checkPurchases(s:SaleOrder) {

    //se il prodotto è un servizio, prendi line.purchase_line_ids

    s.order_line.values.forEach(l => {
      if (!l.display_type && l.product_id.value.detailed_type == 'service') {
        l._purchaselineids = l.purchase_line_ids.ids
      }
    })
    s.procurement_group_id.value?.stock_move_ids.values.forEach(m => {
      if (m.picking_code == "incoming") {
        // check moves for product id
        for (let line of s.order_line.values) {
          //if descriprive skip
      
          if (!line.display_type && line.product_id.id == m.product_id.id) {
           
            if (line._quantity_arrived == null) {
              line._quantity_arrived = 0
            }
            line._quantity_arrived = line._quantity_arrived +  m.quantity_done
            line._purchaselineids.push(m.purchase_line_id.id)
          }
        }
      }
    });

    console.log("SALE LINES RESOLVED :", s.order_line.values)

    //for each sale line resolve the purchase lines
    await firstValueFrom(this.odooEm.resolveArray(new PurchaseOrderLine(), s.order_line.values, "purchase_line_ids"))

  //   // let purchasesLines = []
  //   // s.order_line.values.forEach(l => {  
  //   //   purchasesLines = purchasesLines.concat(l.purchase_line_ids.values)
  //   // })

  //   // console.log("PURCHASE LINES RESOLVED :", purchasesLines)
  

  //   //risolvi le account moves legate alle purchase lines
  //   await firstValueFrom(this.odooEm.resolveArray(new AccountMoveLine(), purchasesLines, "invoice_lines"))
  //   //salva i values delle account moves per l'ordine  s
  //   let accs = []
  //   purchasesLines.forEach(l => {
  //     accs = accs.concat(l.invoice_lines.values)
  //   })

  //  console.log("ACCOUNTS RESOLVED :", accs) 
  }
  


  getFilteredRowsForSale(sale:SaleOrder) {
    return sale.order_line?.values
  }

  async onClickPurchase(line:PurchaseOrderLine) {
    //await firstValueFrom(this.odooEm.resolve(l.purchase_line_ids))
    // qst succede solo per se 
   // if (l.purchase_line_ids.values.length){
      //this.router.navigate(['purchase', l.purchase_line_ids.values[0].order_id.id],{relativeTo: this.route} )
    //} else if (order.procurement_group_id.value) {
      this.router.navigate(['purchase', line.order_id.id],{relativeTo: this.route} )
    //}
    this.loading = false
  }

  async onViewPickings(s:SaleOrder) {
    this.router.navigate(['/pickings'],{relativeTo: this.route, queryParams: {'search':s.name}, } )

    
  }

  async checkProcuremntGroup(s:SaleOrder) {
    await firstValueFrom(this.odooEm.resolveSingle(new ProcurementGroup(), s.procurement_group_id))

    if (s.procurement_group_id.value)
      await firstValueFrom(this.odooEm.resolve(s.procurement_group_id.value.stock_move_ids))
    
    s._resolvedProcurement = true
    return s.procurement_group_id.value
  }


  public async draft(s:SaleOrder) {
    this.loading = true
    try {
      await this.odooEm.call(new SaleOrder(), "action_draft",s.id).toPromise()
    } catch(e) {
      this.loading = false
      alert(e.message)
    }
    
    await this.resolveSale(s)
    this.loading = false
  }

  public async cancel(s:SaleOrder) {
    this.loading = true

    let purch = s.purchase_order_count
    if (purch >0){
      this.loading = false
      return alert("Impossibile annullare, ci sono " + purch +" acqusito/i collegato/i " + "\nContatta responsabile acqusiti per modificare l'ordine")
    }
    
    //let pg = await this.checkProcuremntGroup(s)
    // controlla se ci sono picking con qty_done >0
    let done = s.procurement_group_id?.value?.stock_move_ids.values.find(m =>
      m.quantity_done && m.state!=='cancel' && m.location_id.id != ODOO_IDS.stock_location_vendor)
    if (done) {
      this.loading = false
      return alert("Impossibile annullare, ci sono trasferimenti completati " + done.picking_id.name + "\nContatta responsabile magazzino")
    }

    var cancel = await firstValueFrom(this.odooEm.create<SaleOrderCancel>(new SaleOrderCancel(),{
      order_id: s.id
    }))

    try {
      await this.odooEm.call(new SaleOrderCancel(), "action_cancel", [cancel.id]).toPromise()
    } catch(e) {
      this.loading = false
      alert(e.message)
    }
    await this.resolveSale(s)
    this.loading = false
  }

  async check(f) {
    let r = await f
    if (r.error) {
      this.loading = false
      alert(r.error.data.message)
      throw r
    }
    return r
  }


  public async confirm(s:SaleOrder) {

    if (!s.partner_id.value)
      await firstValueFrom(this.odooEm.resolveSingle(new Contact(), s.partner_id))

    if (!s.partner_id.value.ga_arca)
      return alert("Impossibile confermare. Il cliente non ha un codice per la fatturazione.")

    // check every line with a product has a route
    var fs = s.order_line.values.filter(l => (l && l.product_id?.value)  && (l && !l.display_type)  && !l.route_id.id)
    if (fs.length > 0)
      return alert("Tutti i prodotti devono avere un percorso")

    this.loading = true
    await this.check(this.odooEm.call2(new SaleOrder().ODOO_MODEL, "action_confirm",[[s.id]] ))

    await this.resolveSale(s)
    this.loading = false
  }

  async delete(o:SaleOrder) {
    if (!confirm("Confermi di eliminare la nota ?"))
      return
    this.loading = true
    var res = await firstValueFrom(this.odooEm.delete(new SaleOrder(), [o.id])) 
    if (res)
      this.relatedSales.splice(this.relatedSales.indexOf(o),1)
    this.loading = false
  }


  async deleteLine(o:SaleOrder) {

    this.loading = true    
    var ids = o.order_line.values.filter(l => l._checked).map(x => x.id)
    var objs = o.order_line.values.filter(l => l._checked)
    console.log("DELETE LINE", ids, objs)


    let del = []

    //if order is not sale, delete all the lines
    if (confirm("Confermi eliminazione delle righe selezionate?")) {    
    if (o.state != 'sale') {
      del = objs.map(x => x.id)
    } else {
      //if order is sale, delete only notes and sections, and set products quatity to 0, then delete 
      del = objs.filter(x => x.display_type).map(x => x.id)
      if (objs.length > 0) {
        await this.odooEm.updateMultiple(objs, {"product_uom_qty": 0})
      }
      // //here we should endte sale order pickings and set the quantity of moves of the lines to 0
      // let  checkedLines =o.order_line.values.filter(l => l._checked)
      // //solve move ids
      // await this.odooEm.resolveArrayOfSingle(new StockMove(), checkedLines, "move_ids")
      // let moves = []
      // checkedLines.forEach(l => {
      //   moves = moves.concat(l.move_ids.values)
      // })
      // //put quantity to 0
      // await this.odooEm.updateMultiple(moves, {"product_uom_qty": 0})
    }
    if (del.length > 0)
      await this.odooEm.delete(new SaleOrderLine(), del)
  }

  


    // //filter for sections and notes and delete them
    // if (objs.length > 0) {
    //   var n = objs.filter(x => x.display_type)
    //   if (n.length > 0) {
    //     await this.odooEm.delete(new SaleOrderLine(), n.map(x => x.id))
    //   }
    // }

    // // special handle of delete on sale

    // if (o.state == 'sale') {
    //   await this.odooEm.updateMultiple(objs, {"product_uom_qty": 0})
    // } else if (ids.length && confirm('Confermi eliminazione ?')) {
    //   await firstValueFrom(this.odooEm.delete(new SaleOrderLine(), ids)) 
    // }
    
    await this.resolveSale(o)
    this.loading = false
  }

  getMoveClass(m) {
      if (m.state == 'waiting')
        return 'bg-muted'
      if (m.state == 'assigned' )
        return 'bg-success'
      if (m.state == 'done')
        return 'bg-success text-stroke'
      if (m.state == 'confirmed' )
        return 'bg-warning'
  }

  getVariantAttribute(line:SaleOrderLine, name:string) {
    if (!line.product_id)
      return

    if (!line.product_id.value || !line.product_id.value.product_template_attribute_value_ids)
    return ""
    
    if (line.product_id.value.product_template_attribute_value_ids.values) {
      var f = line.product_id.value.product_template_attribute_value_ids.values.filter(value => {
        return value.attribute_id.name.startsWith(name)
      })
    }

    return (f && f.length > 0) ? f[0] : null
  }

  async updateLineRoute(order:SaleOrder,line:SaleOrderLine, route) {
    this.loading = true
    
    // if multiple lines are selected, update all of them but skip notes and sections
    var objs = order.order_line.values.filter(l => l._checked && !l.display_type)

  
    if (objs.length > 0)
      await this.odooEm.updateMultiple(objs, {"route_id": route.id})
    else
      await this.odooEm.update(line, {'route_id': route.id}).toPromise()

    // this.selectLine

    // await this.load()
    await this.resolveSale(order)
  }

  async updateLinePackage(order, line, p) {
    this.loading = true

    var pqty = line.product_uom_qty
    
    await firstValueFrom(this.odooEm.update(line, {
      'product_uom_qty' : pqty,
      'product_packaging_id': p.id
    })) 

    await this.refreshLine(line)
  }



  // hack buttons should be in the inventory
  async onAddNotes(l:SaleOrderLine) {
    
    var s = this.relatedSales.find(x => x._open)
    if (s) {
      await firstValueFrom(this.odooEm.create(new SaleOrderLine(), {
        display_type : l.display_type,
        name: l.name,
        order_id: s.id,
        sequence:10000
      }))

      await this.odooEm.call2(s.ODOO_MODEL,"reorder_order_line",[s.id  ,l.id, 10000])


      this.resolveSale(s)
    }
  }

  async refreshLine(line:SaleOrderLine) {

    this.loading = true
    // refresh the needed computed on line too 
    var fresh =(await this.odooEm.search<SaleOrderLine>(new SaleOrderLine(),[['id','=',line.id]]).toPromise())[0]
    
    line.product_uom_qty = fresh.product_uom_qty
    line.product_packaging_qty = fresh.product_packaging_qty
    
    line.product_packaging_id.id = fresh.product_packaging_id.id
    line.product_packaging_id.name = fresh.product_packaging_id.name
    line.product_packaging_id.value = fresh.product_packaging_id.value = null
    await firstValueFrom(this.odooEm.resolveSingle(new ProductPackaging(), line.product_packaging_id))

    line.price_unit = fresh.price_unit
    line.discount = fresh.discount

    line.forecast_expected_date = fresh.forecast_expected_date
    line.free_qty_today = fresh.free_qty_today
    line.qty_available_today = fresh.qty_available_today
    line.price_subtotal = fresh.price_subtotal
    this.loading = false
  }

  async createSaleFromRows() {
    this.loading = true

    // create new order
    let so = {
      partner_id: this.relatedSales[0].partner_id.id,
      opportunity_id: this.relatedSales[0].opportunity_id.id,
      picking_policy : "direct",
      ga_address: this.relatedSales[0].opportunity_id.value?.street,
      pricelist_id: ODOO_IDS.pricelist_commessa
    }
    var newSo = await firstValueFrom(this.odooEm.create<SaleOrder>(new SaleOrder(), so)) 

    // update the part
    // TODO shoud have automatic external key in odoo
    await this.odooEm.update(this.part, {sale_order_ids: [[4, newSo.id]]}).toPromise()

    await this.loadRelatedSales(this.activeSale)
    await this.resolveSale(this.relatedSales[this.relatedSales.length - 1 ])
    this.loading = false
  }

  async insertProduct(order:SaleOrder, product:Product|ProductWithOnlyVariants, sequence?) {
    // copy route_id from first line
    // let route_id = order.order_line.values.find(l => l.route_id)?.route_id.id
    let route_id = false
    var init = {
      order_id : order.id,
      sequence: sequence,
      product_id: product.id,
      product_uom_qty:0,
      route_id: route_id ? route_id : false
    }

    if (product.packaging_ids.ids && product.packaging_ids.ids.length > 0) {
      init['product_packaging_id'] = product.packaging_ids.ids[0]
    }

    await firstValueFrom(this.odooEm.create(new SaleOrderLine(), init))
    await this.resolveSale(order)
  }
  
  async drop(el:CdkDragDrop<SaleOrder, SaleOrder, SaleOrderLine>) {

    // avviso drag&drop in nota confermata... lo nascondo, non mi serve avviso
    // if (el.container.data.state == 'sale') {
    //   if (!confirm("Stai per retificare un ordine confermato e relative prenotazioni. Confermi ?"))
    //     return
    // }

    
    // else if (el.container.data.state != 'draft')
    //   return alert("Non e' possibile modificare note confermate")

    // new line note/section
    if (el.item.data instanceof SaleOrderLine && 
      !el.item.data.id && 
      (el.item.data.display_type == 'line_note' ||
        el.item.data.display_type == 'line_section')) {

        var ln = {
          order_id : el.container.data.id,
          sequence: 10000,
          name: el.item.data.name,
          product_id: false,
          product_uom_qty:false,
          display_type :  el.item.data.display_type
        }
        
        var line = await firstValueFrom(this.odooEm.create<SaleOrderLine>(new SaleOrderLine(), ln))
        await this.odooEm.call2(el.container.data.ODOO_MODEL,"reorder_order_line",[el.container.data.id  ,line.id, el.container.data.order_line.values[el.currentIndex].sequence])
        await this.resolveSale(el.container.data)
        return
    }

    if (el.item.data instanceof Product || el.item.data instanceof ProductWithOnlyVariants) {
      await this.insertProduct(el.container.data, el.item.data, el.currentIndex)
      return
      // console.log("PODUXT ", el.item.data)

      // // copy route_id from first line
      // // TODO temp patch .. force route DIRETTO outside a lead
      // let route_id
      // if (!el.container.data.origin)
      //   route_id = ODOO_IDS.diretto_route_id
      // else
      // route_id = el.container.data.order_line.values.find(l => l.route_id)?.route_id
      
      // var init = {
      //   order_id : el.container.data.id,
      //   sequence: el.currentIndex,
      //   product_id: el.item.data.id,
      //   product_uom_qty:0,
      //   route_id: route_id
      // }

      // if (el.item.data.packaging_ids.ids && el.item.data.packaging_ids.ids.length > 0) {
      //   init['product_packaging_id'] = el.item.data.packaging_ids.ids[0]
      // }

      // await firstValueFrom(this.odooEm.create(new SaleOrderLine(), init))
      // await this.resolveSale(el.container.data)
      // return
    }

    var sourceSale:SaleOrder = el.previousContainer.data
    var targetSale:SaleOrder = el.container.data
    
    //drag and drop solo tra sales in draft!!!
    // escludo dal controllo se è lo stesso sale
    if (sourceSale.id != targetSale.id && (sourceSale.state != 'draft' || targetSale.state != 'draft')) {
      return alert("Non e' possibile spostare righe tra ordini confermati o annullati, solo ordini in bozza")
    }
    this.loading = true
    
    var sourceLine = el.item.data
    if (sourceSale.id != targetSale.id) {
      
      await firstValueFrom(this.odooEm.update(sourceLine, {
        order_id: targetSale.id
      }))
        this.resolveSale(sourceSale)
      await this.resolveSale(targetSale)
    } else {
      await this.odooEm.call2(targetSale.ODOO_MODEL,"reorder_order_line",[targetSale.id  ,el.item.data.id, targetSale.order_line.values[el.currentIndex].sequence])
      await this.resolveSale(targetSale)
    }
    this.loading = false
  } 


  async updateSelectablePackaging(line:SaleOrderLine) {


    await firstValueFrom(this.odooEm.resolve(line.product_id.value.packaging_ids))
    this.selectablePackagings = line.product_id.value.packaging_ids.values
  }

  async updateSelectableRoutes(order:SaleOrder, line:SaleOrderLine) {

    //fetch selected lines except for sections and notes

    var lines = order.order_line.values.filter(l => l._checked && !l.display_type)
    if (lines.length == 0)
      lines = [line]

    // find longer 
    let idx = lines.reduce((p, c, i, a) => a[p].product_id.value.route_from_categ_ids.ids.length < c.product_id.value.route_from_categ_ids.ids.length ? p : i, 0);
    var vals = lines[idx].product_id.value.route_from_categ_ids.ids
    
    lines.forEach(l => {
      vals = vals.filter(x => l.product_id.value.route_from_categ_ids.ids.includes(x));
    })


    // var p = (await firstValueFrom(this.odooEm.search<ProductTemplate>(new ProductTemplate(), [['id', '=', line.product_id.value.product_tmpl_id.id]])))[0]
    // await firstValueFrom(this.odooEm.resolve<StockLocationRoute>(p.route_from_categ_ids))
    this.selectableRoutes = await firstValueFrom(this.odooEm.search<StockLocationRoute>(new StockLocationRoute(), [['id','in',vals]]))
    // this.selectableRoutes = p.route_from_categ_ids.values.filter(r => r.sale_selectable)
  }

  ondragStartQtyColumn(line:SaleOrderLine,event:any) {
    this.draggingQtyColumn = line
    line._checked = true
    var img = new Image();
    img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=';
    event.dataTransfer.setDragImage(img, 0, 0);
    return true
  }


  getInfoMultiple(o) {
    // Todo group x um
    var sl = o.order_line.values?.filter(x => x._checked)
    var sums = {}

    sl?.forEach(x => {
      var k = x.product_id.value?.uom_id?.name
      if (!sums[k])
        sums[k] = 0
      sums[k] = Number(sums[k] + Number(x.product_uom_qty))
    })

    if ( sl?.length < 2) {
      return ""
    }
    
    var s = sl?.length + " righe"
    for (const [key, value] of Object.entries(sums)) {
      s =  s + " - " + parseFloat(Number(value).toFixed(5)) + " " + key
    }
    return s
  }
 


  async resolveLine(line:SaleOrderLine) {
    let lines = await firstValueFrom(this.odooEm.search<PurchaseOrderLine>(new PurchaseOrderLine(), [['id', 'in',  line._purchaselineids ]]))
    console.log("purchase lines" , lines)
    line._purchase_line_values = lines
    //risolvi le account moves legate alle purchase lines
    await firstValueFrom(this.odooEm.resolveArray(new AccountMoveLine(), lines, "invoice_lines"))
    //salva i values delle account moves
    let accs = []
    lines.forEach(l => {
      accs = accs.concat(l.invoice_lines.values)
    })
    console.log("ACCOUNTS RESOLVED :", accs)
  }

  getPurchasesForLine(line:SaleOrderLine,order:SaleOrder):PurchaseOrderLine[] {
 
    //if not purchase for line don't show
    if (!line._quantity_arrived)
      return []
    else 
    // return purchase line
    return line.purchase_line_ids.values



    // let p = []
    // // cicla tutti i movimenti e prendi i movimenti di acquisto
    // order.procurement_group_id.value?.stock_move_ids.values?.forEach(m => {

    //   if (m.purchase_line_id.id && m.product_id.id == line.product_id.id) {
    //     // if (m.purchase_line_id.value.state != 'cancel'))
    //     //let f = p.find(x => x?.id == m.purchase_line_id?.id)
      
    //     //if (f && m.purchase_line_id.value)

    //     if ( m.purchase_line_id.value)
    //       p.push(m.purchase_line_id.value)
    //     //}
    //   }
    // })

    // //this.loading = false
    // return p
    


  }
 


  getArrivedDate(line:SaleOrderLine,order:SaleOrder) {

    let moveids = line._purchase_line_values?.map(x => x.move_ids.ids).flat()
    console.log("getArrivedDate", moveids)
    
    
    let ls =  order.procurement_group_id.value?.stock_move_ids.values?.filter(
      m => {
        return moveids?.includes(m.id)
      }
    )
    console.log("getArrivedDate", ls)

    return ls
    
  }



  // function to get the purchase price or ref cost of a sale order line
  getPurchasePrice(line:SaleOrderLine, order:SaleOrder) {

    let price = 0
    console.log("starting now getPurchasePrice", line.product_id.value.name)

    //se la line ha _puschase_line_values, verifica che abbia account moves e prendine il valore price_unit
    if (line._purchase_line_values?.length > 0) {
      
      let totalQty = 0
      let Subtotal = 0

        line._purchase_line_values.forEach(p => {
          // if invoice lines are present, sum the quantity and total
          if (p.invoice_lines.values.length > 0) {
            console.log("FOUND INVOICE LINES")
            p.invoice_lines.values.forEach(i => {
              totalQty = totalQty + i.quantity
              Subtotal = Subtotal + i.price_subtotal
              console.log("INVOICED", i.quantity, i.price_subtotal)
            })
          }
          // if no invoice lines, take the price_unit and quantity from the purchase line
          else {
            totalQty = totalQty + p.product_qty
            Subtotal = Subtotal + p.price_subtotal
            console.log("NOT INVOICED, ONLY PURCHASE", p.product_qty, p.price_subtotal)
          }
        })
        price = Subtotal / totalQty
        console.log("PRICE", Subtotal,"/", totalQty, "=", price)
      
      
    }
    // if no purchase lines, return line.purchase_price
    
    if ( price==0 && line.purchase_price > 0) {
      price = line.purchase_price
      console.log("PURCHASE PRICE", price)
    // if no purchase price, return line.product_id.value.standard_price
    } else if (price==0 && line.product_id.value.standard_price > 0) {
      price = line.product_id.value.standard_price
      console.log("STANDARD PRICE", price)
    }




      return price
    }


  //   // cicla tutti i movimenti e prendi i movimenti di acquisto
  //   order.procurement_group_id.value?.stock_move_ids.values?.forEach(m => {
  //     if (m.purchase_line_id.id && m.product_id.id == line.product_id.id) {
  //       //let f = p.find(x => x?.id == m.purchase_line_id?.id)

  //       // check if we have not resolved purchese line already
  //       if (m.purchase_line_id.id && !m.purchase_line_id.value)
  //         return "--"
  //       if (m.purchase_line_id.value)
  //         p.push(m.purchase_line_id.value)
  //     }
  //   })



    
  //   if (p.length > 0) {
  //      return p[0].price_unit
  //   }
  //    // Se line.purchase_price è maggiore di 0, restituisce line.purchase_price
  // if (line.purchase_price > 0) {
  //   return line.purchase_price;
  // }
  // // Altrimenti, se line.product_id.value.standard_price è maggiore di 0, restituisce line.product_id.value.standard_price
  // else if (line.product_id.value.standard_price > 0) {
  //   return line.product_id.value.standard_price;
  // }
  // // Altrimenti, restituisce line.product_id.value.lst_price
  // else {
  //   return line.product_id.value.lst_price;
  // }




  // getAvailable(line:SaleOrderLine, order:SaleOrder) {
  //   return line.product_id.value.qty_available
  // }

  getFree(line: SaleOrderLine, order: SaleOrder) {

    if (order.state == 'sale' && !order.procurement_group_id.value)
       return 0


    if ( !line.product_id.value || !line.product_packaging_id.value)
      return  0
    
    
    //let's define the available quantity:
    // if free qty = avaiolable qty then
    // if outgoing qty =0 retunr available qty
    // else return available qty - outgoing qty
    
    let t;
    let available = 0
    // if available - outgoing >0 return available - outgoing, else 0
    available = line.product_id.value.qty_available - line.product_id.value.outgoing_qty
    if (available < 0)
      available = 0



    // if (line.product_id.value.qty_available == line.product_id.value.free_qty) {
    //   if (line.product_id.value.outgoing_qty == 0){
    //     available = line.product_id.value.qty_available
    //     console.log("ENTERING AVAILABNLE QTY")}
    //   else{
    //     available = line.product_id.value.qty_available - line.product_id.value.outgoing_qty
    //   console.log("ENTERING SUBTRACTION")}
    // } else {
    //   available = line.product_id.value.free_qty - line.product_id.value.outgoing_qty
    //   console.log("ENTERING FREE QTY")
    // }

    console.log("QUANTITIES FOR PRODUCT: ", line.product_id.value.name, "AVAILABLE", line.product_id.value.qty_available, "FREE", line.product_id.value.free_qty, "OUTGOING", line.product_id.value.outgoing_qty)
    console.log("QUANTITY FREE", available)
    
    if (line.product_id.value.detailed_type === 'consu') {
      t = '9999+' + " " + line.product_uom.name;
    } else if (line.product_packaging_id.id && line.product_packaging_id.value && available) {

     t = Number(new Decimal(available)
        .div(Number(line.product_packaging_id.value?.qty))
        .toFixed(5))
        .toLocaleString() + " " + line.product_packaging_id.name;
    } else {
      t = available + " " + line.product_id.value?.uom_id?.name;
    }

    return t;
  }

  getReserved(line:SaleOrderLine, order:SaleOrder) {
    console.log("ENTERNING GET RESERVED FOR LINE" , line.product_id.value.name)

    //exit if no procurement group or no quantity on this line
    if (!order.procurement_group_id.value || line.product_uom_qty == 0)
      return 0
    // total reserve
    let r = 0


    order.procurement_group_id.value?.stock_move_ids.values?.forEach(m => {
      if (m.product_id.id == line.product_id.id) {
        if (m.reserved_availability)
         r = r + m.reserved_availability  
      }
    })


    if (line.qty_delivered > 0)
      r = r + line.qty_delivered
    
    let t




    if (line.product_packaging_id.id )  {

      t = Number(new Decimal(r).div(line.product_packaging_id.value.qty).toFixed(5)).toLocaleString() + " " + line.product_packaging_id.name
    }
    else if (line.product_id.value) 
      t = r + " " + line.product_id.value?.uom_id?.name
    else 
      t  =" -- "

    return t
  }

  getToShip(line:SaleOrderLine, order:SaleOrder) {

    //If no procurement group or sale line quantity, return 0
    if (!order.procurement_group_id.value || line.product_uom_qty == 0)
      return 0
    let t
    if (line.product_packaging_id.id) 
      t = Number(new Decimal(line.qty_to_deliver).div(line.product_packaging_id.value?.qty).toFixed(5)).toLocaleString() + " " + line.product_packaging_id?.name
    else 
      t = line.qty_to_deliver + " " + line.product_id.value?.uom_id?.name

    return t
  }


  getShipped(line:SaleOrderLine, order:SaleOrder) {
    //If no procurement group or sale line quantity, return 0
    if (!order.procurement_group_id.value || line.product_uom_qty == 0)
      return
    let t
    if (line.product_packaging_id.id) 
      t = Number(new Decimal(line.qty_delivered).div(line.product_packaging_id.value.qty).toFixed(5)).toLocaleString() + " " + line.product_packaging_id.name
    else 
      t = line.qty_delivered + " " + line.product_id.value?.uom_id?.name

    return t
  }


  // isService(line:SaleOrderLine, order:SaleOrder) {

  //   return false
  //   var is = []
  //   if (line.product_id.value.detailed_type == 'service' && order.state == 'sale') {
  //     is.push("fa fa-shopping-cart text-success")
  //     return is
  //   }
  // }
  

  getIconsForLine(line: SaleOrderLine, order: SaleOrder) {

    let is = []
    // ORDER STATE = SALE
    if (order.state == 'sale') {

      //SERVICE: if it's a service and it has a purchase line, always show as arrived, no prenotations, no deliveries
      if (line.product_id.value.detailed_type == 'service' && line.purchase_line_ids.ids.length) {
        is.push('fa fa-truck-arrow-right text-success')
      }

      //PRODUCTS:  if it's not a service log arrivals, prenotations and deliveries

  
      if (line.product_id.value.detailed_type != 'service' && line.product_uom_qty > 0) {
     
        // check quantity arrived
        if (line && line._quantity_arrived == 0) {
          is.push('fa fa-truck-arrow-right text-danger')
        } else if (line && line._quantity_arrived > 0 && line.product_uom_qty > line._quantity_arrived) {
          is.push('fa fa-truck-arrow-right text-warning')
        } else if (line && line._quantity_arrived > 0 && line.product_uom_qty <= line._quantity_arrived) {
          is.push('fa fa-truck-arrow-right text-success')
        }
      


        // check quantity reserved
        let r = 0
        order.procurement_group_id.value?.stock_move_ids.values?.forEach(m => {
          if (m.product_id.id == line.product_id.id) {
            r = r + m.reserved_availability //sommo la quantità riservata
          }
        })
        r = r + line.qty_delivered //se ho la quantità consegnata la sommo (è prenotata...)

        if (Number((line.product_uom_qty).toFixed(5)) <= Number((r).toFixed(5))) {
          is.push('fa fa-lock text-success')
        } else if (Number((line.product_uom_qty).toFixed(5)) > Number((r).toFixed(5))) {
          is.push('fa fa-lock  text-warning')
        } else {
          is.push('fa fa-lock text-muted')
        }
      

        // check ships
        let delivered = Number((line.qty_delivered).toFixed(5))
        let to_deliver = Number((line.qty_to_deliver).toFixed(5))
        let qty = Number((line.product_uom_qty).toFixed(5))


          if (delivered == 0)
            is.push('fa fa-truck-fast text-danger')
          else if (delivered > 0 && delivered < qty)
            is.push('fa fa-truck-fast text-warning')
          else if (delivered >= qty || to_deliver == 0)
            is.push('fa fa-truck-fast text-success')
        
      }
    }
    //ORDER STATE = DRAFT
    if (order.state == 'draft') {
      // free qty seems to round badly floats
      // line.product_uom_qty <= Number((line.virtual_available_at_date).toFixed(4))
      if (Number((line.product_uom_qty).toFixed(5)) <= Number((line.free_qty_today).toFixed(5)) || line.product_id.value.detailed_type == 'consu') {
        is.push('fa fa-warehouse text-success')
      }
      else if (line.product_uom_qty > line.free_qty_today) {
        is.push('fa fa-warehouse text-warning')
      } else {
        is.push('fa fa-warehouse text-danger')
      }

    }
    return is


    // let p = []
    // order.procurement_group_id.value?.stock_move_ids.values?.forEach(m => {
    //   if (m.purchase_line_id.id && m.product_id.id == line.product_id.id) {
    //     let f = p.find(x => {
    //       if (x && x.purchase_line_id.id == m.purchase_line_id.id) {
    //         return true
    //       }
    //   })
    //     if (!f) {
    //       p.push(m)
    //       founded = true
    //         is.push('fa fa-truck-arrow-right text-primary')

    //     }
    //   }
    // })


    // <i *ngIf="line.qty_delivered == 0 && line.qty_delivered < line.product_uom_qty && order.state != 'draft'"
    //     class="fa-duotone fa-truck-fast text-danger me-2 fa-lg"></i>

    //   <i *ngIf="line.qty_delivered > 0 && line.qty_delivered < line.product_uom_qty && order.state != 'draft'"
    //     class="fa-duotone fa-truck-fast text-warning me-2 fa-lg"></i>

    //   <i *ngIf="line.qty_delivered > 0 && line.qty_delivered >= line.product_uom_qty && order.state != 'draft'" 
    //     class="fa-duotone fa-truck-fast text-success me-2 fa-lg"
    //   ></i>

  }

  async updateOrder(order:SaleOrder ,val:string, field, isNumber=false) {


    this.loading = true
    let j = {}
    if (isNumber)
      j[field] = Number(val)
    else
      j[field] = val
    await firstValueFrom(this.odooEm.update<SaleOrder>(order, j)) 

    if (field == 'pricelist_id') {
      if (confirm("vuoi aggiornare i prezzi ?"))
        await this.odooEm.check(this.odooEm.call2(order.ODOO_MODEL,"action_update_prices",[order.id]))
    }
    order[field] = val
    
    if (field == 'commitment_date' || field == 'pricelist_id') {
      await this.resolveSale(order)
    }

    this.loading = false

  }
  
  async updateLine(order:SaleOrder, line:SaleOrderLine,fieldName:string,value) {

    this.loading = true
    
    const oldVal = Number(line[fieldName]).toString()

    //alert("update " + line.product_uom_qty)
    // save previous

   
    // blocca modifica percorso (è già disabled in html)
    // if (order.state == 'sale' && fieldName == 'route_id') {
    //   alert("Non è possibile modificare il percorso su un ordine confermato")
    //   await this.resolveLine(order, line)
    //   return
    // }

    // blocca modifica quantità se ci sono ordini collegati
    console.log("TRYING TO UPDATE LINE", line)
    if (order.state == 'sale' && line._purchaselineids.length > 0 && (fieldName == 'product_uom_qty' || fieldName == 'product_packaging_qty')) {
      alert("Questo è materiale già ordinato su misura. Contattare responsabie acqusiti per modificare la quantità")
      await this.resolveLine(line)
      this.loading = false
      return
    }





    // ad ordine confermato posso sempre aumentare le quantità
    // posso ridurre le quantità solo se non è stato preparato
    // preparato = ci sono stock moves con qtydone completata con l'articolo che sto riducendo, che non originano da ODOO_IDS.stock_location_vendor
    let done = order.procurement_group_id?.value?.stock_move_ids.values.find(m =>
       m.quantity_done && m.state!=='cancel' && m.location_id.id != ODOO_IDS.stock_location_vendor && m.product_id.id == line.product_id.id)
    if (order.state == 'sale' && done && fieldName == 'product_uom_qty' && value < line.product_uom_qty
    || order.state == 'sale' && done && fieldName == 'product_packaging_qty' && value < line.product_packaging_qty)  {  
      

      line[fieldName] = Number(oldVal)
      this.changeDetectorRef.detectChanges()
      
      alert("Non è possibile ridurre la quantità confermata di "+ line.product_id.name +" perchè è già stato preparato. Contatta responsabile magazzino")
      this.loading = false

      


     
      //await this.resolveLine(order, line)
      return
    }

    // qui escludo qualsiasi controllo se c'è display type, ovvero se la line è una nota o sezione

    if (order.state == 'sale' && !line.route_id.id && !line.display_type) {
      alert("Seleziona un percorso per " + line.product_id.name)
     // await this.resolveLine(order, line)
      return

     } 
    // else if (order.state == 'sale' && !line.display_type) {
    //   // if (!confirm("Stai per retificare un ordine confermato e relative prenotazioni. Confermi ?"))
    //   //   return
    // }
    
    // special handle for package Netto that needs to be multiple of a single piece
    if (fieldName == 'product_packaging_qty') {
      var r = await firstValueFrom(this.odooEm.search<ProductPackaging>(new ProductPackaging(),[['id', 'in', line.product_id.value.packaging_ids.ids]]))
      var netti = r.find(x => x.name.includes('netti'))
      var pezzo = r.find(x => x.name.toLowerCase() == 'pz')

      if (netti && pezzo) {
        // var pzlor = (value * netti.qty) / pezzo.qty
        line.product_uom_qty = Number(value) * line.product_packaging_id.value.qty
        line.product_packaging_qty = Number(value)
      } else if (pezzo && line.product_packaging_id.name == 'Pz') {
        line.product_uom_qty =  Number(value * pezzo.qty)
        line.product_packaging_qty = value
        // var pzlor = (value * pezzo.qty) / pezzo.qty
        // line.product_uom_qty = Number((Math.ceil(pzlor) * pezzo.qty).toFixed(4))
        // line.product_packaging_qty = Number(value)
      } else {

        line.product_uom_qty = Number(value) * line.product_packaging_id.value.qty
        line.product_packaging_qty = Number(value)
      }
      
      var n = {}
      // need to reassign priceunit on change discount don't know why
      n['price_unit'] = line.price_unit
      n['discount'] = line.discount
      n['product_packaging_qty'] = line.product_packaging_qty
      n['product_uom_qty'] = line.product_uom_qty
      n['product_packaging_id'] = line.product_packaging_id?.id
    } else if (fieldName == 'discount') {
      var n = {}
      // need to reassign priceunit on change discount don't know why
      n['price_unit'] = line.price_unit
      n[fieldName] = Number(value)

    } else if (fieldName == 'price_unit') {
      // need to reassign priceunit on change discount don't know why
      var n = {}
      n['price_unit'] = line.price_unit
      n['discount'] = line.discount
      n[fieldName] = Number(value)
    } else {
      var n = {}
      // ATTENZIONE 
      if (typeof(line[fieldName]) === "number")
        n[fieldName] = Number(value)
      else
        n[fieldName] = value
    

      n['discount'] = line.discount
      n['price_unit'] = line.price_unit
      n['product_packaging_id'] = line.product_packaging_id.id
    }

    var lll = await firstValueFrom(this.odooEm.update<SaleOrderLine>(line, n))

    // refresh the needed computed on line too 
    var fresh =(await this.odooEm.search<SaleOrderLine>(new SaleOrderLine(),[['id','=',line.id]]).toPromise())[0]

    line.product_uom_qty = fresh.product_uom_qty
    line.product_packaging_qty = fresh.product_packaging_qty
    
    
      await firstValueFrom(this.odooEm.resolveSingle(new ProductPackaging(), fresh.product_packaging_id))
      // up
    line.product_packaging_id.id = fresh.product_packaging_id.id
    line.product_packaging_id.name = fresh.product_packaging_id.name
    line.product_packaging_id.value  = fresh.product_packaging_id.value
    // line.product_packaging_id.id = fresh.product_packaging_id.id
    // line.product_packaging_id.value = fresh.product_packaging_id.value
    // line.product_packaging_id.name = fresh.product_packaging_id.name

    line.price_unit = fresh.price_unit
    line.discount = fresh.discount
    line.forecast_expected_date = fresh.forecast_expected_date
    line.free_qty_today = fresh.free_qty_today
    line.qty_available_today = fresh.qty_available_today
    line.price_subtotal = fresh.price_subtotal



    // silently refresh total
    this.odooEm.search<SaleOrder>(new SaleOrder(), [['id', '=', order.id]]).subscribe(x => {
      order.amount_untaxed = x[0].amount_untaxed
    })

    this.loading = false
  }

  isLineDoubleUM(line) {
    return true
  }


  isLengthEditable(line) {
    return line.product_id.value.product_tag_ids?.ids.includes(ODOO_IDS.length_editable_tag_id)
  }

  isWidthEditable(line) {
    return line.product_id.value.product_tag_ids?.ids.includes(ODOO_IDS.width_editable_tag_id)
  }

  isHeightEditable(line) {
    return line.product_id.value.product_tag_ids?.ids.includes(ODOO_IDS.height_editable_tag_id)
  }

  isAttributeEditable(line:SaleOrderLine, att:number) {
    return line.product_id.value?.product_template_attribute_value_ids.values?.find(ptav => ptav.attribute_id.id == 33)
  }


  isLineEditable(line:SaleOrderLine) {
    return line.product_id.value?.product_template_attribute_value_ids.values?.find(ptav => ptav.attribute_id.id == 33)
  }

  getDescriptive(line:SaleOrderLine) {
    if (!line.product_id.value) 
      return


    if (line.product_uom_qty == 0 || !line.product_id.value.packaging_ids.values)    // dont want to show 
      return 
    
    var ps = line.product_id.value.packaging_ids.values.slice().sort((a,b) => b.qty - a.qty)
    var q = line.product_uom_qty
    
    var d = ""

    ps = ps.filter(x => (!x.name.includes("netti") && x.sales == true))
    
    var totale = new Decimal(q)

    ps.forEach((p, i) => {

      if (!p.sales || p.name.includes("netti")) 
        return

      if (totale.toNumber() <= 0)
        return 

      let quo;
      //if last
      if (i == ps.length - 1)
        quo = totale.div(p.qty)
      else
        quo = totale.divToInt(p.qty)
      totale = totale.minus(quo.mul(p.qty))
      
      if (quo.toNumber() > 0)
        d = d + "" + Number.parseFloat(quo.toFixed(5)).toLocaleString("it-IT") + " " + p.name + "\n"

    })
    
    return d
  }

  async updateLine2(order, line, field, val) {
    await this.updateLine(order,line, field,val)
  }


  copy(s) {
    navigator.clipboard.writeText(s);
  }
 

  // async updateVariant4(order:SaleOrder, line:SaleOrderLine,e, attributeName) {
  //   let xx = await firstValueFrom(this.odooEm.search<ProductTemplateAttributeValue>(new ProductTemplateAttributeValue(), []))
  //   console.log("XXXXXXXX ", xx)

  //   this.odooEm.search<ProductTemplateAttributeValue>(
  //     new ProductTemplateAttributeValue(),
  //     [['attribute_id' , '=', 33], ['name', '=', e], ['product_tmpl_id', '=', line.product_id.value.product_tmpl_id.id]]
  //   ).subscribe(async x => {
      
  //     if ( x.length == 0) { 


  //       const av = await firstValueFrom(this.odooEm.search<ProductAttributeValue>(new ProductAttributeValue(),{
  //         name: e,
  //         attribute_id: 33
  //       }))

  //       x[0] = await firstValueFrom(this.odooEm.create<ProductTemplateAttributeValue>(new ProductTemplateAttributeValue(), {
  //         product_tmpl_id: line.product_id.value.product_tmpl_id.id, 
  //         product_attribute_value_id: e,
  //         attribute_id: 33,
  //         name: e
  //       }))
  //     }

  //     let old = line.product_id.value.product_template_attribute_value_ids.values.find(x => {
  //      return x.attribute_id.id == 33
  //     })

  //     let ids = line.product_id.value.product_template_attribute_value_ids.ids
  //     ids.splice(ids.indexOf(old.id), 1, x[0].id)

  //     var r:any = await this.odooEm.odoorpcService.sendRequest('/api/sale/create_product_variant', {
  //       "product_template_attribute_value_ids": JSON.stringify(ids),
  //       "product_template_id": line.product_id.value.product_tmpl_id.id
  //     });

  //     // this.odooEm.update(line, {
  //     //   'product_template_attribute_value_ids': [[6, false, ids]]
  //     // }).subscribe(x => {
  //     //   console.log("XXXXX", x)
  //     // })
  //   })
  // }


  async updateVariant3(order:SaleOrder, line:SaleOrderLine,e, attributeName) {

    this.loading = true
    var l = line.product_id.value.product_template_attribute_value_ids.values.find(v => v.attribute_id.name.startsWith(attributeName))
    var li = line.product_id.value.product_template_attribute_value_ids.values.indexOf(l)
    await this.odooEm.resolveSingle(new ProductTemplate(), line.product_id.value.product_tmpl_id).toPromise()

    var tmpl = line.product_id.value.product_tmpl_id.value

    let v
    let oldv:ProductAttributeValue[] = await this.odooEm.search<ProductAttributeValue>(new ProductAttributeValue(), 
        [["name","=", e],
        ["attribute_id","=", l.attribute_id.id]]
      ).toPromise()
      
    if (oldv.length > 0) 
      v = oldv[0]
    else {
      // new attribute value
      v = await this.odooEm.create<ProductAttributeValue>(new ProductAttributeValue(), {
        "name": e,
        "attribute_id": l.attribute_id.id
      }).toPromise()
    }

    await this.odooEm.resolve(tmpl.attribute_line_ids).toPromise()
    var tal = tmpl.attribute_line_ids.values[li]
    var ids = tal.value_ids.ids
    ids.splice(ids.length,0,v.id)
    
    // faster than
    // PATCH ODOO
    // await this.odooEm.update(tmpl, {
    //   attribute_line_ids:[[1, tal.id, {value_ids: [[4, v.id]]}]]
    //     //  attribute_line_ids:[[1, tal.id, {value_ids: [[6, false, ids]]}]]
    // }).toPromise()
    await this.odooEm.update(tal, {value_ids: [[4, v.id]]}).toPromise()
    
    // create product template
    await firstValueFrom( this.odooEm.create(new ProductTemplateAttributeValue(), {
      "product_attribute_value_id" : v.id,
      "attribute_line_id": tal.id,
      "ptav_active": true
    }))

    let ptav:ProductTemplateAttributeValue[] = await this.odooEm.search<ProductTemplateAttributeValue>(new ProductTemplateAttributeValue(), 
      [
        ["attribute_id","=", l.attribute_id.id],
        ["product_attribute_value_id", "=", v.id],
        ["product_tmpl_id","=", l.product_tmpl_id.id],
        ["ptav_active", "=", true]
      ]
    ).toPromise()
    
    var ids = line.product_id.value.product_template_attribute_value_ids.values.map(v => v.id)
    ids[li] = ptav[0].id

    var r:any = await this.odooEm.odoorpcService.sendRequest('/api/sale/create_product_variant', {
      "product_template_attribute_value_ids": JSON.stringify(ids),
      "product_template_id": line.product_id.value.product_tmpl_id.id
    });
    
    // todo PEZZO PACK
    await this.odooEm.run(666, r.result, "product.product" )

    var freshP = (await this.odooEm.search<Product>(new Product(), [['id', '=', r.result]]).toPromise())[0]
    if (freshP) {
      var u = await this.odooEm.update<SaleOrderLine>(line, {
        'product_id':freshP.id,
        'product_uom_qty': line.product_uom_qty,
        'product_packaging_id': freshP.packaging_ids.ids[0]
      }).toPromise()
  
      // force refresh for the line
      var freshline = (await this.odooEm.search<SaleOrderLine>(new SaleOrderLine(), [['id', '=', line.id]]).toPromise())[0];
      // // dirty selective update
      await this.odooEm.resolve(freshP.product_template_attribute_value_ids).toPromise();
      await this.odooEm.resolve(freshP.packaging_ids).toPromise();
      (line.product_id as any).value = freshP;
      line.product_id.id = freshP.id;
      line.product_id.name = freshP.display_name;
      line.product_uom_qty = freshline.product_uom_qty;
      line.product_packaging_qty = freshline.product_packaging_qty
      line.product_packaging_id.id  = freshline.product_packaging_id.id
      
      this.loading = false
      
    } else {
      alert("Errore nella creazione della variante")
    }
  }


  innerText(s:string) {
    if (s)
      return s.replace("<p>", "").replace("</p>", "")
  }

  async replenish($event, order:SaleOrder,p:Product,qty) {
    if (!confirm("Confermi retifica della quantità a magazzino ?")) {
      $event.stopPropagation()
      return
    }

    let q = await firstValueFrom(this.odooEm.create<StockQuant>(new StockQuant(), {
      product_id: p.id,
      location_id:ODOO_IDS.stock_location_stock,
      inventory_quantity_set: true,
      inventory_quantity: (this.replenishQty * this.replenishPackaging.qty) - p.qty_available 
    }))

    await this.odooEm.call2("stock.quant", "action_apply_inventory", [[q.id]])
    await this.resolveSale(order)
  }

  getMoves(line:SaleOrderLine) {
    if (!line || !line.move_ids.values )
    return []

    // todo - getting just the last one ?
    var next = line.move_ids.values[line.move_ids.values.length -1]
    var moves = []
    // var next = original
    
    // while(next != null) {
    //   moves.push(next)
    //   if (next.move_orig_ids.ids.length > 0) {
    //     console.log("NEXT2",next)
    //     var x = this.moves.find(m => next.move_orig_ids.ids.includes(m.id)) 
    //     next = x
    //   } else {

    //     console.log("NEXTNULL")

    //     next = null
    //   }

    // }
    return this.moves.filter(m => {

      return m.product_id.id == line.product_id.id
    })
  }


  getOrderStateName(s): string {
    switch (s) {
      case 'draft':
        return 'Bozza';
      case 'sale':
        return 'Confermato';
      case 'cancel':
        return 'Annulato';
    }
  }


  async openPicking(p:StockPicking) {
    this.detailPicking = p
  }

  getNewNote() {
    var s = new SaleOrderLine()
    s.display_type = 'line_note'
    s.name = "Nuova nota"
    return s
  }

  getNewSection() {
    var s = new SaleOrderLine()
    s.display_type = 'line_section'
    s.name = "Nuova sezione"
    return s
  }


  async print(s:SaleOrder) {
    this.activeSale = s

    var lines = []
    this.relatedSales.forEach(o => {
      if (o.order_line && o.order_line.values?.length > 0) {

        // if (o._checked)
        //   lines = lines.concat(o.order_line.values)
        lines = lines.concat(o.order_line.values.filter(l => l._checked))
      }
    })
    
    if (lines.length == 0 && !this.activeSale?.order_line.values) {
      this.activeSale = await this.resolveSale(this.activeSale)
      lines =  this.activeSale?.order_line.values
    }
    else if (lines.length == 0 && this.activeSale?.order_line.values) {
      lines =  this.activeSale?.order_line.values
    }
  
    this.orderLinesToPrint = lines;


    this.isPrinting = true
  }

  round(val) {
  return val
  }

//   pickedOut(s:SaleOrder) {
//     //cerca se ci sono trasferimenti in uscita completati
//   let picked = s.procurement_group_id.value?.stock_move_ids?.values?.find(m => m.location_dest_id.id == ODOO_IDS.stock_location_sent && m.state == 'done')
//    console.log("PICKED ", picked)  
//    if (picked)
//     return true
//    else 
//     return false
//   }

//   orderArrived(s:SaleOrder) {
//     // controlla tra i trasferimenti se ce n'è uno di tipo ricevuto completato
//     let arrived = s.procurement_group_id.value?.stock_move_ids?.values?.find(m => m.location_id.id == ODOO_IDS.stock_location_vendor && m.state == 'done')
//     console.log("ARRIVED ", arrived)
//     if (arrived)
//       return true
//     else
//       return false
// }

//  orderPrepared(s:SaleOrder) {
//   // controlla tra i trasferimenti se ce ne sono completati che non vengono da customer e non vanno a vendor
//   let prepared = s.procurement_group_id.value?.stock_move_ids?.values?.find(m => (m.state == 'done' && m.location_id.id != ODOO_IDS.stock_location_vendor) && (m.state == 'done' && m.location_dest_id.id != ODOO_IDS.stock_location_customer))
//   console.log("PREPARED ", prepared)  
//   if (prepared)
//     return true
//   else
//     return false
// }


  printPick() {
    // this.gapi.printOrder(
    //   PRINTS_CFG.pickingPrintDriveFile,
    //   PRINTS_CFG.spool_folder_id)
    // this.activeSale.order_line.values

    
  }

}

