import { OdooMultiRelationship } from './odoo-multi-relationship.model';
import { IConnectable, TrelloCardEntry2 } from './trello-card';
import { OdooModel } from './odoo-model.model';
import { StockMoveLine } from './stock-move-line';
import { StockMove } from './stock-move';
import { DriveFolder } from './drive.folder';
import { OdooRelationship } from './odoo-relationship.model';
import { Product } from './product.model';
import { SaleOrder } from './sale-order.model';
import { UomUom } from './uom-uom.model';
import { ProcurementGroup } from './procurement.group.model';
import { StockLocation } from './stock-location';

export class MrpProduction extends OdooModel implements IConnectable<MrpProduction> {
  public readonly ODOO_MODEL = 'mrp.production';
  

     readonly ORDER_TYPE_NAME = 'Production';
  
   product_id: OdooRelationship<Product> = new OdooRelationship<Product>();
  id: number;
  move_finished_ids:OdooMultiRelationship<StockMove>= new OdooMultiRelationship<StockMove>(StockMove); // materiale prodotto
  state:string = "" 
  move_raw_ids: OdooMultiRelationship<StockMove> = new OdooMultiRelationship<StockMove>(StockMove); // materiale da consumare
  name: string = ""
  drive_ids: OdooMultiRelationship<DriveFolder>;
  drive_folder_ids: OdooMultiRelationship<DriveFolder>;
  trello_ids: OdooMultiRelationship<TrelloCardEntry2> ;
  trello_card_ids: OdooMultiRelationship<TrelloCardEntry2> ;
  create_date: string = ""
  product_qty: number = 0 //quantità da produrre
  product_uom_id: OdooRelationship<UomUom> = new OdooRelationship<UomUom>();
  qty_producing: number = 0 //quantità prodotta
  procurement_group_id: OdooRelationship<ProcurementGroup> =  new OdooRelationship<ProcurementGroup>();
  sale_order_count: number = 0
  location_src_id: OdooRelationship<StockLocation> = new OdooRelationship<StockLocation>();
  location_dest_id: OdooRelationship<StockLocation> = new OdooRelationship<StockLocation>();

  
  origin:string = ""
  // title:string = "" // custom
  components_availability_state: string;
  date_planned_start:string =""
  create() {
    return new MrpProduction()
  }


}
