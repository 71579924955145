<app-navbar [loading]="loading" backroute="..">
  <a class="navbar-brand">
      Vendite su lista
  </a>
  <ng-container>
      <div class="ms-auto dropdown sticky-top">
          <a class="btn text-white btn-primary dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Nuovo 
          </a>
          <ul class="dropdown-menu dropdown-menu-end">
              <form class="p-3" style="min-width: 440px;">
                  <app-contact-picker2
                      [mode]="'embedded'"
                      class="embedded"
                      [showAddresses]=false
                      (onSelect)="onContact($event)">
                  </app-contact-picker2>
                  <button 
                      [disabled]="!newSale.partner_id.value"
                      class="btn btn-primary text-white mt-3" (click)="$event.stopPropagation();create()">Crea</button>
              </form>
          </ul>
      </div>
  </ng-container>
</app-navbar>

<div class="bg-light px-3 py-3">
  <div class="container-fluid">
    <div class="row g-3">
      <div class="col-md-3 col-lg-3">
        <div class="input-group">
          <span class="input-group-text">Cliente</span>
          <input class="form-control" [ngModel]="searchInput | async" (ngModelChange)="searchInput.next($event)">
        </div>
      </div>
      <div class="col-md-3 col-lg-2">
        <select class="form-select" [(ngModel)]="selectedUser" (change)="load()">
          <option value="">Tutti gli utenti</option>
          <option *ngFor="let user of users" [value]="user">{{user}}</option>
        </select>
      </div>
      <div class="col-md-3 col-lg-4">
        <div class="input-group">
          <span class="input-group-text">Data ordine</span>
          <input type="date" class="form-control" [(ngModel)]="startDate" (change)="load()">
          <span class="input-group-text">a</span>
          <input type="date" class="form-control" [(ngModel)]="endDate" (change)="load()">
        </div>
      </div>
      <div class="col-md-12 col-lg-3">
        <div class="d-flex justify-content-start justify-content-lg-end align-items-center h-100">
          <div class="form-check form-check-inline me-3">
            <input class="form-check-input" type="checkbox" [(ngModel)]="filterToShip" (change)="load()" id="filterToShip">
            <label class="form-check-label" for="filterToShip">Da spedire</label>
          </div>
          <div class="form-check form-check-inline me-3">
            <input class="form-check-input" type="checkbox" [(ngModel)]="filterShipped" (change)="load()" id="filterShipped">
            <label class="form-check-label" for="filterShipped">Spedite</label>
          </div>
          <button class="btn bg-info btn-sm ms-2 px-3 text-light" (click)="resetFilters()">Reset Filtri</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="table-wrapper h-100" [hidden]="loading">
  <table class="table table-bordered align-middle">
        <thead class="bg-light">
            <tr>
                <th>Numero</th>
                <th>Cliente</th>
                <th>Creato da</th>
                <th>Data ordine</th>
                <th>Importo</th>
                <th>Produzione</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let s of sales">
                <td  routerLink="s/{{s.id}}" class="text-nowrap"  >
                  <span title="Spedito parzialmente" class="fa fa-circle text-warning " *ngIf="s.state == 'sale' && s.delivery_status == 'partial'"></span>
                  <span title="Confermato - Non spedito" class="fa fa-circle text-primary " *ngIf="s.state == 'sale' && s.delivery_status == 'pending'"></span>
                  <span title="Spedito interamente" class="fa fa-circle text-success " *ngIf="s.state == 'sale' && s.delivery_status == 'full'"></span>
                  <span title="Confermato" class="fa fa-circle text-primary " *ngIf="s.state == 'sale' && !s.delivery_status"></span>
                  <span title="Bozza" class="fa fa-circle text-dark " *ngIf="s.state == 'draft'"></span>
                  <span title="Annullato" class="fa fa-circle  text-light" *ngIf="s.state == 'cancel'"></span>
                    <span class="ms-3">{{s.name}}</span>
                </td>
                <td routerLink="s/{{s.id}}" class="text-muted">
                  <strong>{{s.partner_id?.name}} </strong>{{s.ga_title ? ' - ' + s.ga_title : ''}}
                </td>

                <td   routerLink="s/{{s.id}}" class="text-muted">
                  {{s.user_id?.name}}
                  <!-- {{p.group_id.value?.sale_id.value?.partner_id?.name}} -->
              </td> 
                
                <td class="text-muted">
                    {{s.date_order | Date4Humans }}
                </td>
                <td class="text-muted text-nowrap">
                    € {{s.amount_untaxed | number : '1.0-3':'it-IT'}}
                    <!-- {{p.scheduled_date | Date4Humans }} -->
                </td>
                <td class=" text-end">
                  <div class="d-flex text-nowrap text-end align-items-center">

                    <ng-container *ngIf="s.state == 'sale' && s.delivery_status">
                      <app-activity-scheduler [sale]="s"></app-activity-scheduler>
                    </ng-container>
      

                  <app-connect-to
                    class="d-block-inline text-nowrap text-end"
                    faSize="fa-2x"
                    [driveFolders]="s.drive_folder_ids.values"
                    [connectable]="s"
                    type="Produzione"
                    [driveSource]="cfg?.produzione_src"
                    [driveTarget]="cfg?.produzione_dst"
                    [driveTargetTitle]="s.name + ' ' + (s?.ga_title ? s?.ga_title : '') + '-' + s.partner_id.name"
                    (onConnect)="onConnect(s,$event)"
                    [trelloTitle]="s.name + ' ' + (s?.ga_title ? s?.ga_title : '') + '-' + s.partner_id.name"
                    [trelloCards]="s.trello_card_ids.values"
                    [trelloListId]="cfg?.produzione_trello"
                    [trelloBoardId]="cfg?.produzione_trello_board" 
                    >
                    <!-- [driveLinkTitle]="cfg.produzione_link_name + ' > ' + s.name + ' ' + (s?.ga_title ? s.ga_title : '')" -->
                  </app-connect-to>
                </div>
                </td>
              
            </tr>
        </tbody>
        
    </table>
  </div>
