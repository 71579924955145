<app-navbar [loading]="loading" backroute=".." [leadId]="lead?.id" style="position: sticky; top:0px;z-index:1000">
    <a class="navbar-brand">
        <span>
            &nbsp;<b>Commesse / {{lead?.tracking_code}} - {{lead?.partner_id.name}}</b>
        </span>
    </a>

    <div class="ms-auto dropdown">
        <button class="btn btn-link text-dark" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-bars text-white"></i>
        </button>
        <ul class="dropdown-menu dropdown-menu-end">
            <li><a class="dropdown-item" target="_blank"
                    href="//o3.galimberti.eu/web#id={{lead?.id}}&cids=1&menu_id=331&action=504&model=crm.lead&view_type=form">Apri
                    in Odoo</a></li>
            <li><a class="dropdown-item" target="_blank" (click)="delete()">Elimina</a></li>
        </ul>
    </div>
</app-navbar>

<nav class="bg-light p-3"
    style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='%236c757d'/%3E%3C/svg%3E&#34;);"
    aria-label="breadcrumb">
    <ol class="breadcrumb mb-0 ms-4">
        <li class="breadcrumb-item text-dark" *ngFor="let s of this.stages"
            [ngClass]="{'text-primary': s.id == lead?.stage_id?.id, 'text-dark': s.id != lead?.stage_id?.id}">
            <a (click)="toStatus(s)">{{s.name}}</a>
        </li>
        <!-- <li class="breadcrumb-item active" aria-current="page">Library</li> -->
    </ol>
</nav>

<div class="container-fluid p-5" *ngIf="lead">
    <div class="row"> <!-- prima riga tabella -->
        <div class="col-md-4">

            <div class="card"> <!-- card contenente i dati del lead lead -->
                <!-- <div class="card-header d-flex align-items-center justify-content-between">
                            Informazioni 
                        </div> -->
                <!-- <div class="card-header">
                            {{getStage().name}}
                        </div> -->
                <div class="card-body text-nowrap">
                    <h2 class="mb-1 text-wrap" *ngIf="getStage().sequence > 0">{{lead.name}}</h2>
                    <p class="badge lead mt-0 bg-muted text-white">{{lead.user_id.name}}</p>
                    <input class="form-control form-control-lg mb-3" placeholder="Un titolo*" required
                        (change)="updateDealToOdoo('name')" *ngIf="getStage().sequence == 0" [(ngModel)]="lead.name">
                    <!-- <h2 class="mb-3">{{lead.name}}</h2> -->
                    <div class="form-group row mb-3"> <!-- settore -->
                        <label class="col-lg-3 col-form-label">Settore</label>
                        <div class="col-lg-9">
                            <input disabled class="form-control" [value]="lead.area">
                        </div>
                    </div>

                    <div class="form-group row mb-3"> <!-- cliente -->
                        <label class="col-lg-3 col-form-label">Cliente</label>
                        <div class="col-lg-9">
                            <div class="flex-nowrap d-flex ">
                                <a class="form-control disabled h-100 overflow-hidden"
                                    href="/contact/{{lead.partner_id.id}}" target="_blank">{{lead.partner_id.name}}</a>
                                

                                <div class="dropdown droprxight ms-auto">
                                    <!-- to show modify cliente -->
                                    
                                    <button class="btn btn-link px-1" type="button" id="dropdownMenu" title="Modifica cliente"
                                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="fa-solid fa-user-pen"></i>
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right"
                                        style="min-width: 300px;z-index:9999999">
                                        <form class="px-3 py-3" style="min-width: 440px;">
                                            <app-contact [mode]="'embedded'" class="embedded"
                                                (onSelect)="onContact($event)"></app-contact>
                                        </form>
                                    </div>
                     
                                </div>
                                <!-- new deal -->
                            </div>
                        </div>
                    </div>

                    <div class="form-group row mb-3"> <!-- indirizzo -->
                        <label class="col-lg-3 col-form-label">Indirizzo cantiere</label>
                        <div class="col-lg-9">
                            <input #placesRef="ngx-places" ngx-google-places-autocomplete required class="form-control"
                                (onAddressChange)="onAddressChange($event)" [(ngModel)]="lead.street">
                        </div>
                    </div>

                    <div class="form-group row mb-3"> <!-- città -->
                        <label class="col-lg-3 col-form-label">Città cantiere</label>
                        <div class="col-lg-9">
                            <input class="form-control" disabled [(ngModel)]="lead.city">
                        </div>
                    </div>

                    <div class="form-group row mb-3"> <!-- fornitura -->
                        <label class="col-lg-3 col-form-label">Modalità fornitura</label>
                        <div class="col-lg-9">
                            <div class="btn-group" role="group" aria-label="Basic radio toggle button group">

                                <input type="radio" class="btn-check" name="btnradio" id="btnradio1"
                                    (change)="toggleTag('Fornitura')" [checked]="hasTag('Fornitura')">
                                <label class="btn btn-light" for="btnradio1">Fornitura</label>
                                <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off"
                                    value="fornituraeposa" (ngModelChange)="toggleTag('Fornitura e posa')"
                                    [ngModel]="hasTag('Fornitura e posa')">
                                <input type="radio" id="btnradio2" class="btn-check" name="btnradio2"
                                    (change)="toggleTag('Fornitura e posa')" [checked]="hasTag('Fornitura e posa')">
                                <label class="btn btn-light" for="btnradio2">Fornitura e posa</label>
                            </div>


                        </div>
                    </div>

                  

                </div>



            </div>
            <br>



        </div>



        <div class="col-md-8" style="min-width: 300px; max-width: 900px;"> <!-- qui inserisco i label preventivo-->


            <div class="row">

                <div class="card  card-stage-sibling mb-4 p-0">
                    <div class="card-header">
                        <b>Drive & Trello</b>
                    </div>
                    <div class="card-body d-flex p-0">
                        <div class="d-flex flex-column align-items-center border-end py-3 w-100">
                            <app-connect-to type="Preventivo" [deal]="lead" 
                                [driveSource]="cfg?.src"
                                [driveTarget]="cfg?.dst" 
                                [driveFolders]="filterDriveFolders('Preventivo')"
                                [trelloCards]="filterTrelloCards('Preventivo')"
                                [trelloListId]="cfg?.preventivo_trello_list"
                                [trelloBoardId]="cfg?.preventivo_trello_board" [connectable]="lead"
                                [pos]="'top'" 
                                (onConnect)="loadDeal()"></app-connect-to>
                            <small>
                                Preventivo
                            </small>
                        </div>

                        <div class="d-flex flex-column align-items-center border-end py-3 w-100">
                            <app-connect-to type="Progetto" [deal]="lead" [connectable]="lead"
                                [driveSource]="cfg?.project_src" driveIsMerge="true"
                                [driveTarget]="filterDriveFolders('Preventivo')[0]?.value"
                                [driveFolders]="filterDriveFolders('Progetto')"
                                [trelloCards]="filterTrelloCards('Progetto')"                                
                                [trelloListId]="cfg?.project_trello_list"
                                [trelloBoardId]="cfg?.project_trello_board"
                                [trelloTemplateCard]= "cfg?.project_trello"  [skipAttachments]="true"
                                [pos]="'bottom'"
                                (onConnect)="loadDeal()" (loading)="loading = true">
                            </app-connect-to>
                            <small>
                                Progetto
                            </small>
                              <!-- [trelloTemplateCard]="cfg?.project_trello" -->
                            <!-- [trelloTemplateCard]="filterTrelloCards('Preventivo')[0]?.card_id" -->
                        </div>

                        <div class="d-flex flex-column align-items-center py-3 w-100">
                            <app-connect-to type="POS" [deal]="lead" [driveSource]="cfg?.pos_src"
                                [driveTarget]="cfg?.pos_dst" [driveLinkIn]="filterDriveFolders('Preventivo')[0]?.value"
                                [driveLinkTitle]="cfg.pos_link_name" [driveFolders]="filterDriveFolders('POS')"
                                [trelloCards]="filterTrelloCards('POS')" [trelloListId]="cfg?.pos_trello_list"
                                [trelloBoardId]="cfg?.pos_trello_board" [trelloTemplateCard]="cfg?.pos_template_card"
                                [connectable]="lead" (onConnect)="loadDeal()"
                                (loading)="loading = true"></app-connect-to>
                            <small>
                                POS
                            </small>
                        </div>


                    </div>
                </div>


            </div>
            <div class="row">

                <div class="card card-stage  card-stage-sibling mb-4 p-0" *ngIf="getStageOrder() > 0">
                    <div class="card-header px-3 d-flex align-items-center ">
                        <span class=""><strong>Preventivi su lista</strong></span>


                        <!-- <app-connect-to 
                            class="ms-auto"
                            type="Progetto" [deal]="lead" [connectable]="lead"
                            [driveSource]="cfg?.project_src" driveIsMerge="true"
                            [driveTarget]="filterDriveFolders('Preventivo')[0]?.value"
                            [trelloTemplateCard]="filterTrelloCards('Preventivo')[0]?.card_id"
                            [driveFolders]="filterDriveFolders('Progetto')"
                            [trelloCards]="filterTrelloCards('Progetto')" [trelloListId]="cfg?.project_trello"
                            [trelloBoardId]="cfg?.project_trello_board" [skipAttachments]="true"
                            (onConnect)="loadDeal()" (loading)="loading = true"></app-connect-to> -->



                        <button class="btn btn-link ms-auto " title="Espandi" (click)="toggleResolvePart(offersPart)">
                            <i class="fa-solid fa-arrows-up-down fa-lg"></i>
                        </button>

                        <!-- nuova produzione -->
                        <div class="dropdown">
                            <button class="btn btn-link text-white" type="button" (click)="attachNewOffer()"
                                title="Aggiungi nuovo preventivo">
                                <i class="fa fa-plus text-primary fa-lg"></i>
                            </button>
                        </div>
                    </div>


                    <!-- righe preventivi -->

                    <div *ngIf="offersPart && offersPart?.sale_order_ids.values" class="border" cdkDropList
                        id='part-{{offersPart.id}}' [cdkDropListConnectedTo]="partIds" [cdkDropListData]="offersPart"
                        (cdkDropListDropped)="drop($event)">


                        <div *ngFor="let s of offersPart?.sale_order_ids.values" cdkDrag [cdkDragData]="s">

                            <div class="border-bottom d-flex flex-row align-items-center py-2">
                                <i class="fa fa-bars fa-large mx-3"></i>



                                <a [routerLink]="['/leads', lead.id , 'sale' , s.id]" queryParamsHandling="preserve"
                                    class="ps-3 text-nowrap">
                                    <p class="mb-0 pb-0">{{s.name}}</p>
                                </a>

                                <input class="ms-3 flex-fill text-muted form-control bg-transparent border-0 px-1"
                                    [ngModel]="s.ga_title" (ngModelChange)="renameSale(s, $event)"
                                    [ngModelOptions]="{'updateOn':'blur'}">

                                <div class="ps-2 pe-3 ms-auto">
                                    <div class="d-flex align-items-center justify-content-end text-nowrap"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                 


                </div>

                <ng-container 

                     *ngIf="getStageOrder() > 0">
                

                   <app-billing [lead]="lead" (loading)="loading = $event" class="d-block m-0 p-0" (loading)="loading = $event"></app-billing>
                </ng-container>

                
            </div>

            <!-- <div class="row">
                <div class="card card-stage  card-stage-sibling mb-4 p-0" *ngIf="getStageOrder() > 0">
                    <div class="card-header px-3 d-flex align-items-center ">
                        <span class=""><strong>Contratto e pagamenti</strong></span>
                    </div>
                </div>  
            </div> -->



            <!--  <div class="row">
                
                <div class="card  card-stage p-0 card-stage-sibling mb-4 col-md-4" *ngIf="getStageOrder() > 0">
                    <div class="card-header d-flex align-items-center">
                        &nbsp;Preventivo
                    </div>

                    <div class="card-body text-center d-flex" *ngIf="getStage().sequence > 0">
                        <app-connect-to type="Preventivo" [deal]="lead" [driveSource]="cfg?.src"
                            [driveTarget]="cfg?.dst" [driveFolders]="filterDriveFolders('Preventivo')"
                            [trelloCards]="filterTrelloCards('Preventivo')" [trelloListId]="cfg?.preventivo_trello_list"
                            [trelloBoardId]="cfg?.preventivo_trello_board" [connectable]="lead"
                            (onConnect)="loadDeal()"></app-connect-to>
                    </div>

                </div>
            </div> -->
            <!-- Progetto E POS -->
            <!-- <div class="row align-items-start" *ngIf="getStageOrder() > 1">

                <div class="card col-4 p-0 card-stage card-stage-child card-stage-sibling mb-4">
                    <div class="card-header d-flex align-items-center">
                        &nbsp;Progetto
                    </div>
                    <div class="card-body text-center d-flex flex-wrap"
                        *ngIf="getStage().sequence > 1 && insertingTrello != 'progetto'">
                        <app-connect-to type="Progetto" [deal]="lead" [connectable]="lead"
                            [driveSource]="cfg?.project_src" driveIsMerge="true"
                            [driveTarget]="filterDriveFolders('Preventivo')[0]?.value"
                            [trelloTemplateCard]="filterTrelloCards('Preventivo')[0]?.card_id"
                            [driveFolders]="filterDriveFolders('Progetto')"
                            [trelloCards]="filterTrelloCards('Progetto')" [trelloListId]="cfg?.project_trello"
                            [trelloBoardId]="cfg?.project_trello_board" [skipAttachments]="true"
                            (onConnect)="loadDeal()" (loading)="loading = true"></app-connect-to>
                    </div>
                </div>

                <div class="card col-4 p-0 card-stage mb-4">
                    <div class="card-header d-flex align-items-center">
                        &nbsp;POS
                    </div>
                    <div class="card-body text-center d-flex flex-wrap" *ngIf="getStage().sequence > 1">
                        <app-connect-to type="POS" [deal]="lead" [driveSource]="cfg?.pos_src"
                            [driveTarget]="cfg?.pos_dst" [driveLinkIn]="filterDriveFolders('Preventivo')[0]?.value"
                            [driveLinkTitle]="cfg.pos_link_name" [driveFolders]="filterDriveFolders('POS')"
                            [trelloCards]="filterTrelloCards('POS')" [trelloListId]="cfg?.pos_trello_list"
                            [trelloBoardId]="cfg?.pos_trello_board" [trelloTemplateCard]="cfg?.pos_template_card"
                            [connectable]="lead" (onConnect)="loadDeal()" (loading)="loading = true"></app-connect-to>
                    </div>
                </div>

            </div> progetto e pos -->

            

            <div class="row d-flex align-items-center">

                <!-- Produzione -->
                <div class="card card-stage  card-stage-sibling mb-4 p-0" *ngIf="getStageOrder() > 2">
                    <div class="card-header px-3 d-flex align-items-center justify-content-between">
                        <span><strong>Produzione</strong></span>
                        <div class="dropdown ms-auto">
                            <button class="btn btn-link " type="button" data-bs-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false" title="Importa sotto-commesse da template">
                                <i class="fa fa-file-import fa-lg"></i>
                            </button>
                            <ul class="dropdown-menu">
                                <li *ngFor="let i of importableLeads">
                                    <a class="dropdown-item" target="_blank" (click)="importLead(i)">{{i.name}}</a>
                                </li>
                            </ul>
                        </div>

                        <button class="btn btn-link " title="Espandi" (click)="expandAll()">
                            <i class="fa-solid fa-arrows-up-down fa-lg"></i>
                        </button>

                        <!-- nuovo gruppo -->
                        <div class="dropdown">
                            <button class="btn btn-link text-white" type="button" (click)="attachNewPart()"
                                title="Aggiungi sotto-commessa">
                                <i class="fa fa-plus text-primary fa-lg"></i>
                            </button>
                        </div>
              
                    </div>

                    <div class="d-flex flex-column">

                        <div *ngFor="let p of parts | sortBy:'name'" class="border" cdkDropList id='part-{{p.id}}'
                            [cdkDropListConnectedTo]="partIds" [cdkDropListData]="p"
                            (cdkDropListDropped)="drop($event)">
                            <div class="border-bottom bg-light  d-flex flex-row align-items-center py-2">
                                <!-- <i class="fa fa-bars fa-large ms-3" cdkDragHandle ></i> -->

                                <i *ngIf="!p.sale_order_ids.values" class="px-3 fa-solid fa-caret-right"
                                    title="Espandi sotto-commessa" (click)="toggleResolvePart(p)"></i>
                                <i *ngIf="p.sale_order_ids.values" class="px-3 fa-solid fa-caret-down"
                                    title="Comprimi sotto-commessa" (click)="toggleResolvePart(p)"></i>

                                <input class="form-control bg-transparent border-0 " [ngModel]="p.name"
                                    [ngModelOptions]="{'updateOn':'blur'}"
                                    (ngModelChange)="updatePart(p, 'name', $event)">


                                <button class="btn btn-link text-white me-1" type="button" (click)="attachNewSale(p)"
                                    title="Nuovo ordine">
                                    <i class="fa fa-plus text-primary"></i>
                                </button>
                                <button class="btn btn-link text-white me-1" type="button" (click)="deletePart(p)"
                                    title="Rimuovi sotto-commessa">
                                    <i class="fa-solid fa-trash text-primary"></i>
                                </button>
                            </div>

                            <ng-container *ngIf="p.sale_order_ids.values">

                                <div *ngFor="let s of p.sale_order_ids.values  | sortBy:'ga_order'" cdkDrag
                                    [cdkDragData]="s">

                                    <div class="border-bottom d-flex flex-row align-items-center py-2 ">

                                        <i class="fa fa-bars fa-large mx-3"></i>

                                        <a [routerLink]="['/leads', lead.id , 'sale' , s.id]"
                                            queryParamsHandling="preserve" class=" text-nowrap pe-2" target="_blank">
                                            <p class="mb-0 pb-0">{{s.name}}</p>
                                        </a>

                                        <!-- function could be used to get the output string and colours insted of here                                          -->

                                        <span title="Confermato" class="badge bg-primary "
                                            *ngIf="s._delivery_state =='Confermato'">{{s._delivery_state}}&nbsp;</span>
                                        <span title="Spedito interamente" class="badge  bg-success "
                                            *ngIf="s._delivery_state =='Spedito interamente'">{{s._delivery_state}}&nbsp;</span>
                                        <span title="Confermato - acquisti arrivati" class="badge  bg-primary "
                                            *ngIf="s._delivery_state =='Confermato - acquisti arrivati'">{{s._delivery_state}}&nbsp;</span>
                                        <span title="Preparato - non spedito" class="badge  bg-warning "
                                            *ngIf="s._delivery_state =='Preparato - non spedito'">{{s._delivery_state}}&nbsp;</span>
                                        <span title="Spedito parzialmente" class="badge  bg-warning "
                                            *ngIf="s._delivery_state =='Spedito parzialmente'">{{s._delivery_state}}&nbsp;</span>
                                        <span title="Bozza" class="badge bg-dark "
                                            *ngIf="s._delivery_state =='Bozza'">{{s._delivery_state}}&nbsp;</span>
                                        <span title="Annullato" class="badge bg-light text-dark"
                                            *ngIf="s._delivery_state =='Annullato'">{{s._delivery_state}}&nbsp;</span>



                                        <input
                                            class="ms-3 flex-fill text-muted form-control bg-transparent border-0 px-1"
                                            [ngModel]="s.ga_title" (ngModelChange)="renameSale(s, $event)"
                                            [ngModelOptions]="{'updateOn':'blur'}">

                                        <div class="ps-2 pe-3 ms-auto">

                                            <!-- <span *ngIf="isFirstSale(s)" class="text-danger">PRIMANOTA</span><br> -->
                                            <div class="d-flex align-items-center justify-content-end text-nowrap">


                                                <app-activity-scheduler
                                                    *ngIf="(s.state == 'sale' && s.delivery_status != 'full' && s.delivery_status)"
                                                    class="mb-2" [sale]="s"
                                                    (loading)="loading = $event"></app-activity-scheduler>

                                                <span *ngIf="(s.state != 'sale')" class="text-muted mb-2 me-2">
                                                    <button class="btn btn-sm bg-muted text-white" type="button"
                                                        [routerLink]="['/leads', lead.id , 'sale' , s.id]">
                                                        Conferma
                                                    </button>
                                                </span>


                                                <app-connect-to class="d-block-inline" [connectable]="s"
                                                    type="Produzione" [deal]="lead"
                                                    [driveFolders]="filterProductionDriveFolders(s)"
                                                    [driveSource]="cfg?.produzione_src"
                                                    [driveTarget]="cfg?.produzione_dst"
                                                    [driveLinkIn]="filterDriveFolders('Preventivo')[0]?.value"
                                                    [driveLinkTitle]="cfg.produzione_link_name + ' > ' + s.name + ' ' + (s?.ga_title ? s.ga_title : '')"
                                                    [driveAdditionalTitle]="' > ' + s.name + ' ' + (s?.ga_title ? s?.ga_title : '')"
                                                    [trelloCards]="filterProductionTrelloFolders(s)"
                                                    [trelloListId]="cfg?.produzione_trello"
                                                    [trelloBoardId]="cfg?.produzione_trello_board"
                                                    [trelloDateLabel]="'Spedizione'" (onConnect)="loadDeal()">
                                                </app-connect-to>

                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </ng-container>

                        </div>
                    </div>
                    <!-- fine produzione -->
                </div>

              



                <!-- <div class="card    mb-4 p-0" *ngIf="getStageOrder() >= 3">
                    <div class="card-header px-3 d-flex align-items-center justify-content-between">
                        <b>Controllo costi</b>
                        <button class="btn btn-link " title="Espandi"  *ngIf="!showCosts" (click)="showCosts = !showCosts">
                            <i class="fa-solid fa-arrows-up-down fa-lg"></i>
                        </button>
                    </div>

                    <div class="card-body p-0 pt-4 " *ngIf="showCosts">
                        <app-cost-check-embedded  [lead]="lead" (loading)="loading = $event"></app-cost-check-embedded>
                    </div>

                </div> -->

            </div>
        </div>
    </div>
</div>